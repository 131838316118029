/**
 * 模块名称: 员工异动表组件
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  DatePicker,
  Tooltip,
  Select,
  Modal,
  message,
  Row,
  Col
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpFilter'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
const { RangePicker } = DatePicker

let selectedIds = ''

let filter = {
  name: '',
  companyId: ''
}

//筛选初始值
let exporfilter = {
  staffNo: '',
  staffName: '',
  departmentId: '',
  jobPosition: '',
  groupId: '',
  leaderName: '',
  isOnJob: '',
  reason: '',
  isFormal: '',
  isCorel: '',
  addTime: ''
}

let params = {}
// let arr = []

const StaffAlteration = (props) => {
  const productsRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [department, setDepartment] = useState([])
  const [position, setPosition] = useState([])
  const [stateEmployees, setStateEmployees] = useState([])
  const [personnelType, setPersonnelType] = useState([])
  const [coreStaff, setCoreStaff] = useState([])
  const [businessGroup, setBusinessGroup] = useState([])
  const [visible, setVisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [expand, setExpand] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [statusVal, setStatusVal] = useState('请选择')
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [reason, setReason] = useState([])

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    filter = {
      name: '',
      companyId: ''
    }
    exporfilter = {
      staffNo: '',
      staffName: '',
      departmentId: '',
      jobPosition: '',
      groupId: '',
      leaderName: '',
      isOnJob: '',
      reason: '',
      isFormal: '',
      isCorel: '',
      addTime: ''
    }
    //getCorpList()
    onCommonStaffAlteration()
    getEmployeeList()
  }, [])

  //获取员工异动表
  const getCorpList = () => {
    setLoading(true)
    api.getStaffAlteration({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      name: filter.name
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    api.getCommon({ type: 'staff_job_position' }).then(data => setPosition(data))
    api.getCommon({ type: 'staff_is_onjob' }).then(data => setStateEmployees(data))
    api.getCommon({ type: 'staff_is_formal' }).then(data => setPersonnelType(data))
    api.getCommon({ type: 'staff_is_core' }).then(data => setCoreStaff(data))
    api.getCommon({ type: 'staff_change_reason' }).then(data => setReason(data))
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
    api.getSelectGroupList({ limit: global.paramsLimit }).then(data => setBusinessGroup(data.list)) //事业群
  }

  const HandleCancel = () => {
    setVisible(false) //点击保存筛选弹出框内取消
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    //选择保存筛选下拉框中的内容
    setFieldsValue({
      staffNo: filterCont.staffNo || filterCont.staffNo == 0 ? filterCont.staffNo : undefined,//eslint-disable-line 
      staffName: filterCont.staffName || filterCont.staffName == 0 ? filterCont.staffName : undefined,//eslint-disable-line 
      departmentId: filterCont.departmentId || filterCont.departmentId == 0 ? filterCont.departmentId : undefined,//eslint-disable-line 
      jobPosition: filterCont.jobPosition || filterCont.jobPosition == 0 ? filterCont.jobPosition : undefined,//eslint-disable-line 
      groupId: filterCont.groupId || filterCont.groupId == 0 ? filterCont.groupId : undefined,//eslint-disable-line 
      leaderName: filterCont.leaderName || filterCont.leaderName == 0 ? filterCont.leaderName : undefined,//eslint-disable-line 
      isOnJob: filterCont.isOnJob || filterCont.isOnJob == 0 ? filterCont.isOnJob : undefined,//eslint-disable-line 
      reason: filterCont.reason || filterCont.reason == 0 ? filterCont.reason : undefined,//eslint-disable-line 
      isFormal: filterCont.isFormal || filterCont.isFormal == 0 ? filterCont.isFormal : undefined,//eslint-disable-line 
      isCorel: filterCont.isCorel || filterCont.isCorel == 0 ? filterCont.isCorel : undefined,//eslint-disable-line 
      addTime: filterCont.addTime || filterCont.addTime == 0 ? [moment(filterCont.addTime[0]), moment(filterCont.addTime[1])] : undefined //eslint-disable-line
    })

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //筛选时调接口
  const getEmployeeList = () => {
    // console.log(exporfilter)
    setLoading(true)
    api.getStaffAlteration({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      name: filter.name,
      staffNo: exporfilter.staffNo,
      staffName: exporfilter.staffName,
      departmentId: exporfilter.departmentId,
      jobPosition: exporfilter.jobPosition,
      groupId: exporfilter.groupId,
      leaderName: exporfilter.leaderName,
      isOnJob: exporfilter.isOnJob,
      reason: exporfilter.reason,
      isFormal: exporfilter.isFormal,
      isCorel: exporfilter.isCorel,
      addTimeMin: exporfilter.addTimeMin ? exporfilter.addTimeMin : '',
      addTimeMax: exporfilter.addTimeMax ? exporfilter.addTimeMax : '',
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        exporfilter.staffNo = vals.staffNo || vals.staffNo == 0 ? vals.staffNo : ''//eslint-disable-line
        exporfilter.staffName = vals.staffName || vals.staffName == 0 ? vals.staffName : ''//eslint-disable-line
        exporfilter.departmentId = vals.departmentId || vals.departmentId == 0 ? vals.departmentId : ''//eslint-disable-line
        exporfilter.jobPosition = vals.jobPosition || vals.jobPosition == 0 ? vals.jobPosition : ''//eslint-disable-line
        exporfilter.groupId = vals.groupId || vals.groupId == 0 ? vals.groupId : ''//eslint-disable-line
        exporfilter.leaderName = vals.leaderName || vals.leaderName == 0 ? vals.leaderName : ''//eslint-disable-line
        exporfilter.isOnJob = vals.isOnJob || vals.isOnJob == 0 ? vals.isOnJob : ''//eslint-disable-line
        exporfilter.reason = vals.reason || vals.reason == 0 ? vals.reason : ''//eslint-disable-line
        exporfilter.isFormal = vals.isFormal || vals.isFormal == 0 ? vals.isFormal : ''//eslint-disable-line
        exporfilter.isCorel = vals.isCorel || vals.isCorel == 0 ? vals.isCorel : ''//eslint-disable-line
        exporfilter.addTimeMin = vals.addTime || vals.addTime == 0 ? vals.addTime[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.addTimeMax = vals.addTime || vals.addTime == 0 ? vals.addTime[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        getEmployeeList()
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      staffNo: '',
      staffName: '',
      departmentId: '',
      jobPosition: '',
      groupId: '',
      leaderName: '',
      isOnJob: '',
      reason: '',
      isFormal: '',
      isCorel: '',
      addTime: ''
    }
    resetFields()
    currentPage = 1
    history.replace(match.path)
    getEmployeeList()
    setStatusVal('请选择')
    setStatusValName(undefined)
  }

  const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //获取保存筛选弹框中input值
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    }) //点击保存筛选弹出框内确定的回调函数
  }

  //导出
  const onExportFile = () => {
    // api.getStaffAlteration({ limit: global.paramsLimit }).then(data => data.list.filter(v => arr.push(v.staffId))) //获取异动表中的所有id号
    // let alterationStr = arr.join(',')
    setShowExport(true)
    // api.saveStaffChangeExportTpls({
    //   tplName: '员工异动信息',
    //   fields: 'addTime,reasonCn,staffName,companyName,departmentName,jobPositionCn,jobStationCn,leaderName,remark',
    //   method: 'getStaffChangeList'
    // }).then(data => {
    //   api.saveStaffChangeExport({
    //     dataRange: 1,
    //     tplId: data.id,
    //     fileName: 'staffchange_list',
    //     parame: JSON.stringify(exporfilter)
    //   }).then(data => {
    //     history.push('/importDown/download') //导出成功跳转到下载列表
    //   })
    // })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(selectedRowKeys, selectedRows)
      selectedIds = selectedRowKeys
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  //筛选
  const onCorpFilter = (data) => {
    setDepartment([])
    setFieldsValue({'departmentId': undefined})
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getCorpList()
  }

  //选中所属公司后部门下拉框内容随之变化
  const onDepartmentId = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: exporfilter.companyId }).then(data => setDepartment(data.list)) //部门
  }

  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onCorpFilter} />
      <div className="title">其他选项：</div>
      <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="员工编号">
                  {getFieldDecorator('staffNo')(<Input placeholder="请输入员工编号" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="公司用名">
                  {getFieldDecorator('staffName')(<Input placeholder="请输入员工姓名" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="部门">
                  {getFieldDecorator('departmentId')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false} onDropdownVisibleChange={bool => bool && onDepartmentId()} showSearch={true} optionFilterProp="children">
                      {
                        department.map((v, i) => {
                          return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="职级" >
                  {getFieldDecorator('jobPosition')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {
                        position.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="员工状态">
                  {getFieldDecorator('isOnJob')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {
                        stateEmployees.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="直接主管">
                  {getFieldDecorator('leaderName')(<Input placeholder="请输入直接主管" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="事业群">
                  {getFieldDecorator('groupId')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false} showSearch={true} optionFilterProp="children">
                      {
                        businessGroup.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="变动原因">
                  {getFieldDecorator('reason')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {
                        reason.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="人员类型">
                  {getFieldDecorator('isFormal')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {
                        personnelType.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="核心员工">
                  {getFieldDecorator('isCorel')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {
                        coreStaff.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="变动日期">
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={toggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            {alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
          </span>
        </div>
        <div className="search-btns" style={{ paddingBottom: 10 }}>
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                placeholder="默认请选择"
                style={{ width: 160 }}
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                className={alterationUnfoldPack ? "staffalteration-unfold" : ""}

                dropdownMatchSelectWidth={false}
              >
                {filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
          </div> 
          <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
        </div>             
        <Modal
          title="保存筛选条件"
          visible={visible}
          onOk={HandleOk}
          onCancel={HandleCancel}
        >
          <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
        </Modal>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Tooltip title="导出" placement="bottom">
          <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Tooltip>
      </div>
      <Table
        dataSource={list}
        rowKey="id"
        className="corp-table"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1800 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="日期" dataIndex="addTime" fixed="left" width={180} render={(text, record) => (
          <div>{text.slice(0, 10)}</div>
        )} />
        <Column title="变动原因" dataIndex="reasonCn" width={150} />
        <Column title="公司用名" key="staffName" width={150} render={(text, record) => (
          <Link to={`/hr/employee/details?id=${record.staffId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.staffId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer' }}>{text.staffName}</Link>
        )} />
        <Column title="所属公司" dataIndex="companyName" />
        <Column title="部门" dataIndex="departmentName" />
        <Column title="职级" dataIndex='jobPositionCn' />
        <Column title="岗位级别" dataIndex="jobStationCn" />
        <Column title="直接主管" dataIndex="leaderName" />
        <Column title="说明" dataIndex="remark" />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getstaffChangeExportTpl"
        fieldsUrl="getstaffChangeTplItems"
        saveUrl="savestaffChangeExportTpls"
        exportUrl="exportStaffChange"
        method="export"
        parame={{ ExportType: 'staffChange', id: selectedIds, ...exporfilter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(StaffAlteration)