/**
 * 模块名称: 员工信息管理
 * @author liujingxue@372163.com
 */


import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Employee from './Employee'
import Details from './Details'
import Edit from './Edit'
import Add from './Add'
import StaffAlteration from './StaffAlteration'

const EmployeeIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      {/* 进入新建客户 */}
      <Route path={match.path + '/staffAdd'} component={Add} />
      {/* 进入编辑客户 */}
      <Route path={match.path + '/staffEdit'} component={Edit} /> 
      <Route path={match.path + '/staffAlteration'} component={StaffAlteration} />
      <Route component={Employee} />
    </Switch>
  )
}

export default EmployeeIndex