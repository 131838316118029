/**
 * 模块名称: 添加员工
 * @author yangzhihang@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Select,
  Input,
  Radio,
  DatePicker,
  InputNumber,
  Form,
  message,
  Tooltip,
  Modal,
  Upload,
  Icon
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { upLoadModule } from '@/utils/common'
import EditableTable from '@/components/EditableTable'
import { getDiffYmdBetweenDate } from '@/utils'
import moment from 'moment'
import { 
  regExt,
  politicOptions,
  recruitmentSourceOptions,
  marriageOptions,
  educationOptions,
  majorOptions,
  residenceOptions,
  staffTypeOptions,
  workColumns,
  familyColumns,
} from '@/utils/constant'
import UploadImage from '@/components/UploadImage'

const { Option } = Select
const FormItem = Form.Item
const {Dragger} = Upload

const Add = (props) => {
  const { history, form } = props
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } = form

  const [identityInfo, setIdentityInfo] = useState({})
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [jobPositionOptions, setJobPositionOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const [leaderOptions, setLeaderOptions] = useState([])
  const [stationLevelOptions, setStationLevelOptions] = useState([])
  const [attendanceTimezoneOptions, setAttendanceTimezoneOptions] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  const [leaderDisabled, setLeaderDisabled] = useState(false)
  const [groupInfo, setGroupInfo] = useState({})
  const [workExperienceList, setWorkExperienceList] = useState([])
  const [familyMembersList, setFamilyMembersList] = useState([])
  const [hasPoliticOther, setHasPoliticOther] = useState(false)
  const [entryDate, setEntryDate] = useState('')//到职日期
  const [practicel, setPracticel] = useState('')//是否是实习
  const [entryWorkAgeStart, setEntryWorkAgeStart] = useState('')//入职工龄开始时间
  const [entryWorkAgeEnd, setEntryWorkAgeEnd] = useState('')//入职工龄结束时间
  const [intshDueDate, setIntshDueDate] = useState('')//实习协议到期日
  const [leaveDate, setLeaveDate] = useState('')//离职日期
  //上传附件
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)

  useEffect(() => {
    getCommonInterface()
    setEntryWorkAgeStart(0)
    setEntryWorkAgeEnd(0)
    setFieldsValue({
      entryWorkAgeStart: 0,
      entryWorkAgeEnd: 0
    })
    onEntryWorkAgeBlur('WorkAge', 0)
  }, [])

  useEffect(() => {
    setFieldsValue({
      workExperience: workExperienceList
    })
  }, [workExperienceList])

  useEffect(() => {
    setFieldsValue({
      familyMembers: familyMembersList
    })
  }, [familyMembersList])

  //获取部门列表接口
  const getDepartmentList = () => {
    const companyId = getFieldValue('companyId')
    if (!companyId) return
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId }).then(data => setDepartmentOptions(data.list))
  }

  //获取接口
  const getCommonInterface = () => {
    api.getLeaderList({ limit: global.paramsLimit }).then(data => setLeaderOptions(data.list))
    api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setCompanyOptions(data.list))
    api.getCommon({ type: ['staff_job_position', 'attendance_timezone_show'] }).then(data => {
      const { staff_job_position, attendance_timezone_show } = data
      setJobPositionOptions(staff_job_position)
      setAttendanceTimezoneOptions(attendance_timezone_show)
    })
  }

  const onJob = value => {
    setFieldsValue({'jobStation': undefined})
    if (value >= 3) {
      setFieldsValue({
        isCorel: 1
      })
    } else {
      setFieldsValue({
        isCorel: 0
      })
    }
    if (value === 9) {
      setLeaderDisabled(true)
      setFieldsValue({
        leaderId: undefined
      })
    } else {
      setLeaderDisabled(false)
    }

    let dId = getFieldValue('departmentId'),
    pId = getFieldValue('jobPosition')
    if(dId && pId){
      api.getSelectJobStationLevelList({ 
        limit: global.paramsLimit,
        departmentId: dId, 
        positionId: pId 
      }).then(data => setStationLevelOptions(data.list))
    }
  }

  const onBlurIdentity = evt => {
    const value = evt.target.value.trim()
    if (value !== '' && value.length === 18) {
      // 判断身份证是否重复
      api.getCheckIdentityCardNo({identityCardNo: value}).then(message => {
        if(typeof message === 'string'){
          Modal.warning({
            title: '请注意!',
            content: message
          })
        }
      }).catch(err => console.log(err))
      // 获取身份证信息
      api.getPeopleInfoWithIdNo({ idNo: value }).then(data => {
        setIdentityInfo(data)
      })
    } else {
      setIdentityInfo({
        age: '',
        birth: '',
        birthmonth: '',
        sexCn: '',
      })
    }
  }

  const onCompany = () => {
    setGroupInfo({})
    setFieldsValue({
      departmentId: undefined,
      groupId: undefined
    })
    getDepartmentList()
  }

  const onDepartment = value => {
    setFieldsValue({'jobStation': undefined})
    api.getDepartmentinfo({ id: value }).then(data => {
      const { companyGroupName, groupId } = data
      if (companyGroupName !== '' && groupId !== '') {
        setGroupInfo(data)
        setFieldsValue({
          groupId
        })
      } else {
        setGroupInfo({})
        setFieldsValue({
          groupId: undefined,
        })
      }
    })
    let dId = getFieldValue('departmentId'),
    pId = getFieldValue('jobPosition')
    if(dId && pId){
      api.getSelectJobStationLevelList({ 
        limit: global.paramsLimit,
        departmentId: dId, 
        positionId: pId 
      }).then(data => setStationLevelOptions(data.list))
    }
  }

  const onLeader = value => {
    const { workEmail } = leaderOptions.find(item => item.id === value)
    setFieldsValue({
      leaderEmail: workEmail,
    })
  }

  //到职日期发生变化的回调
  const onEntryDate = (date, dateString) => {
    setEntryDate(dateString)
    onEntryWorkAgeBlur('date', dateString)
    if (date) {
      const { birth } = identityInfo
      if (!birth) {
        setTimeout(() => {
          setFieldsValue({
            entryDate: null
          })
        }, 500)
        message.error('请先填写身份证号码')
        return
      }
      const entryDate = new Date(dateString)
      let endY = entryDate.getFullYear()
      let endM = entryDate.getMonth() + 7
      if (endM > 12) {
        endY = entryDate.getFullYear() + 1
        endM = entryDate.getMonth() + 7 - 12
      }
      if (endM < 10) {
        endM = '0' + endM
      }
      const birthYear = parseInt(birth.slice(0, 4))
      const due = getFieldValue('intshDueDate')
      if (!due) {
        const nowDate = moment(new Date()).format('YYYY-MM-DD')
        const { year, month, day } = getDiffYmdBetweenDate(dateString, nowDate)
        setFieldsValue({
          contractExpireName: year < 5 ? '固定期限' : '无固定期限',
          workAge: `${year}年${month}月${day}日`
        })
      }
      setFieldsValue({
        formalDate: moment(`${endY}-${endM}-01`),
        inductionAge: endY - birthYear,
      })
    } else {
      setFieldsValue({
        formalDate: null
      })
    }
  }

  const onIntshDueDate = (date, dateString) => {
    setIntshDueDate(dateString)
    onEntryWorkAgeBlur('DueDate', dateString)
    if(date) {
      const nowDate = moment(new Date()).format('YYYY-MM-DD')
      const { year, month, day } = getDiffYmdBetweenDate(dateString, nowDate)
      setFieldsValue({
        contractExpireName: year < 5 ? '固定期限' : '无固定期限',
        workAge: `${year}年${month}月${day}日`
      })
    }
  }

  const onPolitic = value => {
    if (value === 5) {
      setHasPoliticOther(true)
    } else {
      setHasPoliticOther(false)
    }
  }

  //保存
  const handleSubmit = evt => {
    evt.preventDefault()
    validateFields((err, values) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      if (!err) {
        delete values.sociologyWorkAge
        if (values.seniorityCert && uploadList.length > 0) {
          values.seniorityCert = uploadList[0].url
        } else {
          values.seniorityCert = ''
        }
        for (let key in values) {
          if (values[key] && typeof values[key].format === 'function') {
            values[key] = values[key].format('YYYY-MM-DD')
          }
        }
        if (values.entryWorkAgeStart !== '' && values.entryWorkAgeEnd !== '') {
          values.entryWorkAge = values.entryWorkAgeStart + '年' + values.entryWorkAgeEnd + '月'
          delete values.entryWorkAgeStart
          delete values.entryWorkAgeEnd
        }
        if (values.workExperience.some(item => item.isNew)) {
          return message.error('工作经历信息未保存')
        }
        if (values.familyMembers.some(item => item.isNew)) {
          return message.error('家庭成员信息未保存')
        }
        setSaveLoading(true)
        api.saveUserInfo(values).then(() => {
          setSaveLoading(false)
          message.success('保存成功')
          history.push('/hr/employee')
        }).catch(err => {
          setSaveLoading(false)
          if (err.msg === '发送新员工通知邮件失败') {
            history.push('/hr/employee')
          }
        })
      } else {
        message.error('请填写必填内容')
      }
    })
  }

  //入职时年的工龄
  const onEntryWorkAgeStart = (e) => {
    setEntryWorkAgeStart(e)
  }

  //入职时月的工龄
  const onEntryWorkAgeEnd = (e) => {
    setEntryWorkAgeEnd(e)
  }

  //失去焦点获取社会工龄
  const onEntryWorkAgeBlur = (key, value) => {
    let str = ''
    if (key == 'WorkAge') {
      str = '0年0月'
    } else if (entryWorkAgeStart !== '' && entryWorkAgeEnd !== '') {
      str = entryWorkAgeStart + '年' + entryWorkAgeEnd + '月'
    }
    api.setSocWorkAge({
      entryWorkAge: str,
      entryDate: key == 'date' ? value : entryDate,
      isPracticel: key == 'Pract' ? value : practicel,
      intshDueDate: key == 'DueDate' ? value : intshDueDate,
      leaveDate: leaveDate
    }).then(res => {
      setFieldsValue({ sociologyWorkAge: res })
    }).catch(err => console.log(err))
  }

  //是否是实习
  const onPracticel = (e) => {
    setPracticel(e.target.value)
    onEntryWorkAgeBlur('Pract', e.target.value)
  }

  // 文件上传
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.jpeg, .png, .jpg, .pdf',
		allowSizeType: 3,
    limitNum: 1
	})

  //取消返回列表页
  const onReturnList = () => history.push('/hr/employee')

  return (
    <div className="staffadd-warp">
      <Form onSubmit={handleSubmit} className="staffadd-Form">
        <div className="white-line"></div>

        <h3 className="staffadd-titles">个人信息</h3>
        <div className="line"></div>
        <FormItem label="真实姓名" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('trueName', {
            rules: [{ required: true, message: '请输入真实姓名' }]
          })(<Input placeholder="请输入姓名" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="手机号" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('personalMobile', {
            rules: [
              { required: true, message: '请输入手机号' },
              { pattern: regExt.phone, message: '手机号格式不正确' }
            ]
          })(<Input placeholder="请输入手机号" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="身份证号码" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('identityCardNo', {
            rules: [
              { required: true, message: '请输入身份证号码' },
              { pattern: regExt.identity, message: '身份证号码格式不正确' }
            ]
          })(<Input placeholder="请输入身份证号码" style={{ width: 240 }} onBlur={onBlurIdentity}/>)}
        </FormItem>
        <FormItem label={<><span style={{color: '#f5222d', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}>*</span> 性别</>} className="staffadd-FormItem staffadd-color">
          <div>{identityInfo.sexCn}</div>
        </FormItem>
        <FormItem label={<><span style={{color: '#f5222d', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}>*</span> 出生日期</>} className="staffadd-FormItem staffadd-color">
          <div>{identityInfo.birth}</div>
        </FormItem>
        <FormItem label={<><span style={{color: '#f5222d', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}>*</span> 出生月份</>} className="staffadd-FormItem staffadd-color">
          <div>{identityInfo.birthmonth}</div>
        </FormItem>
        <FormItem label={<><span style={{color: '#f5222d', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}>*</span> 年龄</>} className="staffadd-FormItem staffadd-color">
          <div>{identityInfo.age}</div>
        </FormItem>
        <FormItem label="婚姻状况" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('marriageStatus', {
            rules: [{ required: true, message: '请选择婚姻状况' }]
          })(
            <Select placeholder="请选择婚姻状况" style={{ width: 240 }}>
              {marriageOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="政治面貌" className={hasPoliticOther ? 'staffadd-politic' : 'staffadd-FormItem' }>
          {getFieldDecorator('politicCountenance')(
            <Select placeholder="请选择政治面貌" style={{ width: hasPoliticOther ? '100%' : 240 }} onChange={onPolitic}>
              {politicOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        {hasPoliticOther && (
          <FormItem className="staffadd-other">
            {getFieldDecorator('politicCounOther')(<Input placeholder="请输入" />)}
          </FormItem>
        )}
        <FormItem label="招聘渠道" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('recruitmentSource', {
            rules: [{ required: true, message: '请选择招聘渠道' }]
          })(
            <Select placeholder="请选择招聘渠道" style={{ width: 240 }}>
              {recruitmentSourceOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="介绍人" className="staffadd-FormItem">
          {getFieldDecorator('introducerName')(<Input placeholder="请输入介绍人" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="个人邮箱" className="staffadd-FormItem">
          {getFieldDecorator('personalEmail')(<Input placeholder="请输入个人邮箱" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="现住址" className="staffadd-FormItem">
          {getFieldDecorator('address')(<Input placeholder="请输入现住址" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="是否有职称证书" className="staffadd-FormItem">
          {getFieldDecorator('isHasTitleCert')(
            <Radio.Group>
              <Radio value={2}>是</Radio>
              <Radio value={1}>否</Radio>
          </Radio.Group>
          )}
        </FormItem>
        <FormItem label="证书等级" className="staffadd-FormItem">
          {getFieldDecorator('certLevel')(<Input placeholder="请输入证书等级" style={{ width: 240 }} />)}
        </FormItem>

        <h3 className="staffadd-titles">学历信息</h3>
        <div className="line"></div>
        <FormItem label="最高学历" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('educationLevel', {
            rules: [{ required: true, message: '请选择最高学历' }]
          })(
            <Select placeholder="请选择最高学历" style={{ width: 240 }}>
              {educationOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="专业类型" className="staffadd-FormItem">
          {getFieldDecorator('majorType')(
            <Select placeholder="请选择专业类型" style={{ width: 240 }}>
              {majorOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="所学专业" className="staffadd-FormItem">
          {getFieldDecorator('majorName')(<Input placeholder="请填写所学专业" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="毕业院校" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('schoolName', {
            rules: [{ required: true, message: '请输入毕业院校' }]
          })(<Input placeholder="请填写毕业院校" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="毕业时间" className="staffadd-FormItem">
          {getFieldDecorator('graduateTime')(<DatePicker style={{ width: 240 }} placeholder="请选择毕业时间" />)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>

        <h3 className="staffadd-titles">工作信息</h3>
        <div className="line"></div>
        <FormItem label="首次参加工作时间" className="staffadd-FormItem">
          {getFieldDecorator('firstWorkTime')(<DatePicker style={{ width: 240 }} placeholder="请选择首次参加工作时间" />)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem label="工作经历" className="staffadd-FormItem form-item-line">
          {getFieldDecorator('workExperience')(<Input className="hidden" readOnly />)}
        </FormItem>
        <EditableTable 
          columns={workColumns} 
          data={workExperienceList}
          setData={setWorkExperienceList}
          maxLength={3}
        />

        <h3 className="staffadd-titles">户口信息</h3>
        <div className="line"></div>
        <FormItem label="户口所在地" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('residenceAddress', {
            rules: [{ required: true, message: '请输入户口所在地' }]
          })(<Input placeholder="请输入户口所在地" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="户口性质" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('residenceType', {
            rules: [{ required: true, message: '请选择户口性质' }]
          })(
            <Select placeholder="请选择户口性质" style={{ width: 240 }}>
              {residenceOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="身份证地址" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('identityAddress', {
            rules: [{ required: true, message: '请输入身份证地址' }]
          })(<Input placeholder="请填写身份证地址" style={{ width: 240 }} />)}
        </FormItem>

        <h3 className="staffadd-titles">工资卡信息</h3>
        <div className="line"></div>
        <FormItem label="银行名称" className="staffadd-FormItem">
          {getFieldDecorator('bankName')(<Input placeholder="银行" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="银行卡号" className="staffadd-FormItem">
          {getFieldDecorator('bankNo')(<Input placeholder="银行卡号" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="银行卡户名" className="staffadd-FormItem">
          {getFieldDecorator('bankUserName')(<Input placeholder="银行卡户名" style={{ width: 240 }} />)}
        </FormItem>

        <h3 className="staffadd-titles">紧急联系人信息</h3>
        <div className="line"></div>
        <FormItem label="紧急联系人姓名" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('emergencyContactName', {
            rules: [{ required: true, message: '请输入紧急联系人姓名' }]
          })(<Input placeholder="紧急联系人姓名" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="紧急联系人与本人关系" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('emergencyContactType', {
            rules: [{ required: true, message: '请输入紧急联系人与本人关系' }]
          })(<Input placeholder="紧急联系人与本人关系" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="紧急联系人联系方式" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('emergencyContactTel', {
            rules: [
              { required: true, message: '请输入紧急联系人联系方式' }, 
              { pattern: regExt.phone, message: '手机号格式不正确' }
            ]
          })(<Input placeholder="紧急联系人联系方式" style={{ width: 240 }} />)}
        </FormItem>

        <h3 className="staffadd-titles">家庭信息</h3>
        <div className="line"></div>
        <FormItem label="子女个数" className="staffadd-FormItem">
          {getFieldDecorator('childrenNum')(<InputNumber placeholder="子女个数" min={0} style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="配偶姓名" className="staffadd-FormItem">
          {getFieldDecorator('spouseName')(<Input placeholder="配偶姓名" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="配偶年龄" className="staffadd-FormItem">
          {getFieldDecorator('spouseAge')(<InputNumber placeholder="配偶年龄" min={0} style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="配偶工作单位" className="staffadd-FormItem">
          {getFieldDecorator('spouseCompany')(<Input placeholder="配偶工作单位" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="配偶职位" className="staffadd-FormItem">
          {getFieldDecorator('spousePosition')(<Input placeholder="配偶职位" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="配偶联系方式" className="staffadd-FormItem">
          {getFieldDecorator('spouseMobile', {
            rules: [
              { pattern: regExt.phone, message: '手机号格式不正确' }
            ]
          })(<Input placeholder="配偶联系方式" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="家庭成员" className="staffadd-FormItem form-item-line">
          {getFieldDecorator('familyMembers')(<Input className="hidden" readOnly />)}
        </FormItem>
        <EditableTable 
          columns={familyColumns} 
          data={familyMembersList}
          setData={setFamilyMembersList}
          maxLength={5}
        />

        <h3 className="staffadd-titles">行业信息</h3>
        <div className="line"></div>
        <FormItem label="近亲属是否在本公司就职" className="staffadd-FormItem">
          {getFieldDecorator('isHasRelatives')(
            <Radio.Group>
              <Radio value={2}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="就职本公司近亲属姓名" className="staffadd-FormItem">
          {getFieldDecorator('relativesName')(<Input placeholder="就职本公司近亲属姓名" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem label="近亲属是否在同行业就职" className="staffadd-FormItem">
          {getFieldDecorator('reltIsSameIndustry')(
            <Radio.Group>
              <Radio value={2}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="就职同行业近亲属姓名" className="staffadd-FormItem">
          {getFieldDecorator('samIndReltName')(<Input placeholder="就职同行业近亲属姓名" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="近亲属就职同行业公司名称" className="staffadd-FormItem">
          {getFieldDecorator('samIndReltCompany')(<Input placeholder="近亲属就职同行业公司名称" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="近亲属就职同行业岗位" className="staffadd-FormItem">
          {getFieldDecorator('samIndReltPostion')(<Input placeholder="近亲属就职同行业岗位" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="近亲属就职同行业入职时间" className="staffadd-FormItem">
          {getFieldDecorator('samIndReltWorkTime')(<DatePicker style={{ width: 240 }} placeholder="近亲属就职同行业入职时间" />)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem label="本人是否在其他单位担任法人、监事等职位" className="staffadd-FormItem">
          {getFieldDecorator('isOthCompLegalPerson')(
            <Radio.Group>
              <Radio value={2}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="在其它公司任岗信息" className="staffadd-FormItem">
          {getFieldDecorator('othCompPostion')(<Input placeholder="在其它公司任岗信息" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>

        <h3 className="staffadd-titles">身份信息</h3>
        <div className="line"></div>
        <FormItem label="个人白底照片" className="staffadd-image">
          {getFieldDecorator('userPic')(<UploadImage data={{ max: 10240, allowExts: 'jpg,jpeg,png' }} />)}
          <div className="img-hint">
            <div>支持扩展名：JPG、JPEG、PNG</div>
            <div>大小不超过10M</div>
          </div>
        </FormItem>
        <FormItem label="身份证人像面" className="staffadd-image">
          {getFieldDecorator('idtPicPositive')(<UploadImage data={{ max: 10240, allowExts: 'jpg,jpeg,png' }} />)}
          <div className="img-hint">
            <div>支持扩展名：JPG、JPEG、PNG</div>
            <div>大小不超过10M</div>
          </div>
        </FormItem>
        <FormItem label="身份证国徽面" className="staffadd-image">
          {getFieldDecorator('idtPicBack')(<UploadImage data={{ max: 10240, allowExts: 'jpg,jpeg,png' }} />)}
          <div className="img-hint">
            <div>支持扩展名：JPG、JPEG、PNG</div>
            <div>大小不超过10M</div>
          </div>
        </FormItem>
        <FormItem label="离职证明" className="staffadd-image">
          {getFieldDecorator('leavCert')(<UploadImage data={{ max: 10240, allowExts: 'jpg,jpeg,png' }} />)}
          <div className="img-hint">
            <div>支持扩展名：JPG、JPEG、PNG</div>
            <div>大小不超过10M</div>
          </div>
        </FormItem>
        <FormItem label="毕业证" className="staffadd-image">
          {getFieldDecorator('diploma')(<UploadImage data={{ max: 10240, allowExts: 'jpg,jpeg,png' }} />)}
          <div className="img-hint">
            <div>支持扩展名：JPG、JPEG、PNG</div>
            <div>大小不超过10M</div>
          </div>
        </FormItem>
        <FormItem label="银行卡" className="staffadd-image">
          {getFieldDecorator('bankCard')(<UploadImage data={{ max: 10240, allowExts: 'jpg,jpeg,png' }} />)}
          <div className="img-hint">
            <div>支持扩展名：JPG、JPEG、PNG</div>
            <div>大小不超过10M</div>
          </div>
        </FormItem>

        <h3 className="staffadd-titles">岗位信息</h3>
        <div className="line"></div>
        <FormItem label="所属公司" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('companyId', {
            rules: [{ required: true, message: '请选择所属公司' }]
          })(
            <Select 
              placeholder="请选择"
              onSelect={onCompany} 
              style={{ width: 240 }}
              showSearch={true}
              optionFilterProp="children"
            >
              {companyOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="所属部门" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('departmentId', {
            rules: [{ required: true, message: '请选择所属部门' }]
          })(
            <Select 
              placeholder={getFieldValue('companyId') === undefined ? `请先选择所属公司` : `请选择`} 
              style={{ width: 240 }} 
              onSelect={onDepartment} 
              dropdownMatchSelectWidth={false}
              showSearch={true}
              optionFilterProp="children"
              >
              {departmentOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="所属事业群" className="staffadd-FormItem">
          {getFieldDecorator('groupId')(
            <Select 
              style={{ width: 240 }} 
              showSearch={true}
              optionFilterProp="children"
              placeholder="全部"
              disabled
            >
              <Option value={groupInfo.groupId}>{groupInfo.companyGroupName}</Option>
            </Select>
          )}
        </FormItem>
        <FormItem label="职级" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('jobPosition', {
            rules: [{ required: true, message: '请选择职级' }]
          })(
            <Select placeholder="请选择" style={{ width: 240 }} onSelect={onJob}>
              {jobPositionOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="直接主管" className={`staffadd-FormItem ${leaderDisabled ? '' :'staffadd-required'}`}>
          {getFieldDecorator('leaderId', {
            rules: [{ required: !leaderDisabled, message: '请选择直属主管' }]
          })(
            <Select
              placeholder="请选择"
              style={{ width: 240 }}
              onSelect={onLeader} 
              disabled={leaderDisabled}
              showSearch={true}
              optionFilterProp="children"
            >
              {leaderOptions.map((v, i) => <Option value={v.id} key={i}>{v.staffName}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="员工类型" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('staffType')(
            <Select placeholder="请选择" style={{ width: 240 }}>
              {staffTypeOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="试用期考核级别" className="staffadd-FormItem">
          {getFieldDecorator('prbtAsmLevel')(<Input placeholder="试用期考核级别" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="转正后考核级别" className="staffadd-FormItem">
          {getFieldDecorator('formAsmLevel')(<Input placeholder="转正后考核级别" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="职位中文名称" className="staffadd-FormItem">
          {getFieldDecorator('positionName')(<Input placeholder="职位中文名称" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="岗位级别 (注：须先选择部门和职级)" className="staffadd-FormItem">
          {getFieldDecorator('jobStation')(
            <Select placeholder="请选择" style={{ width: 240 }} showSearch={true} optionFilterProp="children">
              {stationLevelOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="实习" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('isPracticel', {
            rules: [{ required: true, message: '请选择是否是实习生' }]
          })(
            <Radio.Group onChange={onPracticel}>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="实习管理" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('isPracticelManager', {
            rules: [{ required: true, message: '请选择是否是实习管理' }],
          })(
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="管理层" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('isManager', {
            // rules: [{ required: true, message: '请选择是否是管理层' }],
          })(
            <Radio.Group>
              <Radio value={2}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>

        <h3 className="staffadd-titles">入职时间</h3>
        <div className="line"></div>
        <FormItem label="到职日期" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('entryDate', {
            rules: [{ type: 'object', required: true, message: '请选择到职日期' }]
          })(<DatePicker style={{ width: 240 }} placeholder="到职日期" onChange={onEntryDate} />)}
        </FormItem>
        <Tooltip title="选择到职日期后自动填写" placement="topLeft">
          <FormItem label="转正日期" className="staffadd-FormItem">
            {getFieldDecorator('formalDate')(<DatePicker className="staffadd-FormItem-formalDate" disabled style={{ width: 240 }} placeholder="转正日期" />)}
          </FormItem>
        </Tooltip>
        <FormItem label="提前转正日期" className="staffadd-FormItem">
          {getFieldDecorator('formalInFrontDate')(<DatePicker style={{ width: 240 }} placeholder="提前转正日期" />)}
        </FormItem>
        <FormItem label="合同到期日期" className="staffadd-FormItem">
          {getFieldDecorator('contractExpireDate')(<DatePicker style={{ width: 240 }} placeholder="合同到期日期" />)}
        </FormItem>
        <FormItem label="合同期限" className="staffadd-FormItem">
          {getFieldDecorator('contractExpireName')(<Input style={{ width: 240 }} disabled />)}
        </FormItem>
        <FormItem label="换签日期" className="staffadd-FormItem">
          {getFieldDecorator('signChangeDate')(<DatePicker style={{ width: 240 }} placeholder="换签日期" />)}
        </FormItem>
        <FormItem label="实习协议到期日" className="staffadd-FormItem">
          {getFieldDecorator('intshDueDate')(<DatePicker style={{ width: 240 }} placeholder="实习协议到期日" onChange={onIntshDueDate} />)}
        </FormItem>
        <FormItem label="入职节点年龄" className="staffadd-FormItem">
          {getFieldDecorator('inductionAge')(<Input style={{ width: 240 }} disabled />)}
        </FormItem>
        <FormItem label="当前节点年龄" className="staffadd-FormItem">
          <div>{identityInfo.age}</div>
        </FormItem>
        <FormItem label="时间区分" className="staffadd-FormItem">
          {getFieldDecorator('timeDivision')(<Input placeholder="时间区分" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>

        <h3 className="staffadd-titles">入职信息</h3>
        <div className="line"></div>
        <FormItem label={<><span style={{color: '#f5222d', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}>*</span> 入职时社会工龄</>} className="staffadd-FormItem">
            <FormItem style={{ display: 'inline-block' }}>
              {getFieldDecorator('entryWorkAgeStart', {
                rules: [{ required: true, message: '请选择入职时社会工龄' },{
                  pattern: /^([1-9]\d*|[0]{1,1})$/,
                  message: '请选择正确入职时社会工龄',
                }]
              })(<InputNumber min={0} step={1} onChange={onEntryWorkAgeStart} onBlur={onEntryWorkAgeBlur}/>)}
            </FormItem>
            <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>年</span>
            <FormItem style={{ display: 'inline-block'}}>
              {getFieldDecorator('entryWorkAgeEnd', {
                rules: [{ required: true, message: '请选择入职时社会工龄' },{
                  pattern: /^([1-9]\d*|[0]{1,1})$/,
                  message: '请选择正确入职时社会工龄',
                }]
              })(<InputNumber min={0} max={11} step={1} onChange={onEntryWorkAgeEnd} onBlur={onEntryWorkAgeBlur}/>)}
            </FormItem>
            <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>月</span>
        </FormItem>
        <FormItem label="公司工龄" className="staffadd-FormItem">
          {getFieldDecorator('workAge')(<Input style={{ width: 240 }} disabled />)}
        </FormItem>
        <FormItem label="社会工龄" className="staffadd-FormItem">
          {getFieldDecorator('sociologyWorkAge')(<Input style={{ width: 240 }} disabled />)}
        </FormItem>
        <FormItem label="公司用名" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('staffName', {
            rules: [{ required: true, message: '请输入公司用名' }]
          })(<Input placeholder="公司用名" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="公司邮箱" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('workEmail', {
            rules: [
              { required: true, message: '请输入公司邮箱' },
              { pattern: regExt.mail, message: '请输入合法的公司邮箱' }
            ]
          })(<Input placeholder="公司邮箱" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="企业QQ" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('enterpriseQQ')(<Input placeholder="企业QQ" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="社会工龄证明" className="staffadd-image">
          {getFieldDecorator('seniorityCert')(<div className="dragger-box" ref={draggerRef}>
            <Dragger
              {...uploadFiles}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
              <p className="ant-upload-hint">
                支持扩展名：jpg、jpeg、png、pdf 单个文件大小不超过10M，只能上传1个附件。
              </p>
            </Dragger>
          </div>)}
        </FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>
        <FormItem className="staffadd-FormItem"></FormItem>

        <h3 className="staffadd-titles">其他信息</h3>
        <div className="line"></div>
        <FormItem label="考勤号" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('attendanceNum', {
            rules: [{ required: true, message: '请输入考勤号' }]
          })(<Input placeholder="考勤号" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="考勤时区" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('attendanceTimezone', {
            initialValue: 1,
            rules: [{ required: true, message: '请选择考勤时区' }]
          })(
            <Select placeholder="请选择考勤时区" style={{ width: 240 }}>
              {attendanceTimezoneOptions.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="核心员工" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('isCorel', {
            rules: [{ required: true, message: '请选择是否是核心员工' }],
          })(
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="员工状态" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('isOnJob', {
            initialValue: 1,
            rules: [{ required: true, message: '请选择员工状态' }]
          })(
            <Radio.Group disabled>
              <Radio value={1}>在职</Radio>
              <Radio value={0}>离职</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="员工登录OA账号" className="staffadd-FormItem">
          {getFieldDecorator('userName', {
            rules: [
              { pattern: regExt.account, message: '允许输入6-50位英文、数字、符号，英文不区分大小写' }
            ]
          })(<Input placeholder="员工登录OA账号" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="直接主管公司邮箱" className="staffadd-FormItem">
          {getFieldDecorator('leaderEmail', {
            rules: [
              { pattern: regExt.mail, message: '请输入合法的公司邮箱' }
            ]
          })(<Input placeholder="直接主管公司邮箱" style={{ width: 240 }} />)}
        </FormItem>
        <FormItem label="账号状态" className="staffadd-FormItem staffadd-required">
          {getFieldDecorator('isValidAccount', {
            initialValue: 0,
            rules: [{ required: true, message: '请选择账号状态' }]
          })(
            <Radio.Group>
              <Radio value={0}>生效</Radio>
              <Radio value={1}>失效</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem className="staffadd-btngroup">
          <BtnGroup onCancel={onReturnList} loading={saveLoading} />
        </FormItem>
      </Form>
    </div>
  )
}

export default Form.create()(Add)
