/**
 * 模块名称: 离职管理详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  message,
  Spin,
  Form,
  Radio,
  DatePicker,
  Upload,
  Select
} from 'antd'
import moment from 'moment'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import ControlledDatePicker from '../components/ControlledDatePicker'
import AgreementFiles from '../components/AgreementFiles'
import { upLoadModule } from '@/utils/common'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'
const { TextArea } = Input
const { Dragger } = Upload
const FormItem = Form.Item
const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
const { Option } = Select
const DimDetail = (props) => {
  const { history } = props
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } = props.form
  const draggerlwRef = useRef(null)
  const draggerwtRef = useRef(null)
  const draggerczRef = useRef(null)
  const [detailLoading, setDetailLoading] = useState(true)
  const [detailData, setDetailData] = useState({})
  const [detailDataTwo, setDetailDataTwo] = useState({})
  const [oldDetailDataTwo, setOldDetailDataTwo] = useState({})
  const [oldDetailData, setOldDetailData] = useState({}) //原来数据
  const [detailDataId, setdetailDataId] = useState('')
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState([])
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [updateVisible, setUpdateVisible] = useState(false) //补充资料弹框
  const [updateVal, setUpdateVal] = useState('')  //补充资料意见
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [uploadVisible, setUploadVisible] = useState(false)
  const [fileListlw, setFileListlw] = useState([])//劳务合同上传附件
  const [fileListwt, setFileListwt] = useState([])//委托书上传附件
  const [fileListcz, setFileListcz] = useState([])//辞职信上传附件
  const [leaveJobTypeCn, setLeaveJobTypeCn] = useState('')//离职方式
  const [selfHandle, setSelfHandle] = useState('')//是否本人办理离职
  const [terminationAgreement, setTerminationAgreement] = useState([])//合同解除协议显示数据
  const [letterResignation, setLetterResignation] = useState([])//辞职信显示数据
  const [powerAttorney, setPowerAttorney] = useState([])//委托书显示数据
  const search = parseSearch(props.location.search)
  const [timeValue, setTimeValue] = useState(null) // 工资结算时间默认值

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getStaffLeavejobInfo({ id: search.id }).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setLeaveJobTypeCn(data.leaveJobTypeCn)
        if (data.terminationAgreement) {
          setTerminationAgreement(JSON.parse(data.terminationAgreement))
        }
        if (data.letterResignation) {
          setLetterResignation(JSON.parse(data.letterResignation))
        }
        if (data.powerAttorney) {
          setPowerAttorney(JSON.parse(data.powerAttorney))
        }
        setDetailData(data)
        setDetailDataTwo(data.staffInfo)
        setOldDetailDataTwo(data.oldData && data.oldData.staffInfo ? data.oldData.staffInfo : data.staffInfo)
        setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
        setdetailDataId(data.id)
        if (data.wagesSettleTime && data.editExtendAuth) {
          setTimeValue(data.wagesSettleTime) // 工资结算时间默认值
        }
        setStatusList(data.procedureStatusList) // 审批记录
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setDetailLoading(false)
      }).catch((err) => {
        if (err.code === 200404) {
          setTimeout(() => {
            history.goBack()
          }, 1500)
          return
        }
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  const detail = () => {
    api.getStaffLeavejobInfo({ id: search.id }, true).then(data => {
      setLeaveJobTypeCn(data.leaveJobTypeCn)
      if (data.terminationAgreement) {
        setTerminationAgreement(JSON.parse(data.terminationAgreement))
      }
      if (data.letterResignation) {
        setLetterResignation(JSON.parse(data.letterResignation))
      }
      if (data.powerAttorney) {
        setPowerAttorney(JSON.parse(data.powerAttorney))
      }
      setDetailData(data)
      if (data.wagesSettleTime && data.editExtendAuth) {
        setTimeValue(data.wagesSettleTime) // 工资结算时间值
      }
      setDetailDataTwo(data.staffInfo)
      setOldDetailDataTwo(data.oldData && data.oldData.staffInfo ? data.oldData.staffInfo : data.staffInfo)
      setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
      setdetailDataId(data.id)
      setStatusList(data.procedureStatusList) // 审批记录
      setLogConList(data.logList) // 审批日志
      setButtonAuthList(data.buttonAuthList) // 审批权限
    }) 
  }

  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    api.setStaffLeavejobPass({
      id: detailDataId,
      remark: pasDesc
    }).then(data => {
      detail() //确认通过后
    })
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.setStaffLeavejobReject({
      id: detailDataId,
      remark: textAreaVal
    }).then(data => {
      detail()//确认驳回后
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 转上级
  const onTurnUp = () => {
    api.setStaffLeavejobTurnUp({
      id: detailDataId,
      remark: ''
    }).then(data => {
      history.push('/hr/dimission')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.setStaffLeavejobReplenish({
      id: detailDataId,
      remark: updateVal
    }).then(data => {
      detail()
    }).catch()
    setUpdateVisible(false)
  }

  //上传资料弹出弹出框
  const onUpload = () => {
    setUploadVisible(true)
  }

  //提交上传添加
  const onUploadInfo = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        //劳务合同解除
        let uploadListlw = []
        for (let i = 0, k = fileListlw.length; i < k; i++) {
          if (fileListlw[i].status !== 'removed') {
            uploadListlw.push({
              url: fileListlw[i].url,
              name: fileListlw[i].name
            })
          }
        }
        //委托书
        let uploadListwt = []
        for (let i = 0, k = fileListwt.length; i < k; i++) {
          if (fileListwt[i].status !== 'removed') {
            uploadListwt.push({
              url: fileListwt[i].url,
              name: fileListwt[i].name
            })
          }
        }
        //辞职信
        let uploadListcz = []
        for (let i = 0, k = fileListcz.length; i < k; i++) {
          if (fileListcz[i].status !== 'removed') {
            uploadListcz.push({
              url: fileListcz[i].url,
              name: fileListcz[i].name
            })
          }
        }
        //劳务合同解除提示填写
        if (!uploadListlw.length > 0) {
          return message.error('请补充完整后提交')
        }
        //如果有辞职信 没有填写 提示填写
        if (leaveJobTypeCn == '主动离职' && !uploadListcz.length > 0) {
          return message.error('请补充完整后提交')
        }
        //如果有委托书，没有填写 提示填写
        if (selfHandle == 0 && !uploadListwt.length > 0) {
          return message.error('请补充完整后提交')
        }
        // console.log(vals)
        api.setstaffLeavejobUploadInfo({
          id: search.id,
          wagesSettleDate: vals.wagesSettleDate ? vals.wagesSettleDate.format('YYYY-MM-DD') : '',
          tureLeaveDate: vals.tureLeaveDate ? vals.tureLeaveDate.format('YYYY-MM-DD') : '',
          wagesSettleTime: vals.wagesSettleTime,
          isSelfHandle: vals.isSelfHandle,
          stopAccount: vals.stopAccount,
          terminationAgreement: JSON.stringify(uploadListlw),
          letterResignation: uploadListcz.length > 0 ? JSON.stringify(uploadListcz) : '',
          powerAttorney: uploadListwt.length > 0 ? JSON.stringify(uploadListwt) : '',
          uploadedInfoDesc: vals.uploadedInfoDesc ? vals.uploadedInfoDesc : ''
        }).then(res => {
          message.success('上传成功')
          setUploadVisible(false)
          detail()
        })
      }
    })
  }

  // 附件上传 劳务合同解除
  const uploadFileslw = upLoadModule({
    fileList: fileListlw,
    setFileList: setFileListlw,
    draggerRef: draggerlwRef,
    accept: '.rar, .zip, .jpg, .png, .pdf',
    limitNum: 20
  })

  // 附件上传 委托书
  const uploadFileswt = upLoadModule({
    fileList: fileListwt,
    setFileList: setFileListwt,
    draggerRef: draggerwtRef,
    accept: '.rar, .zip, .jpg, .png, .pdf',
    limitNum: 20
  })

  // 附件上传 辞职信
  const uploadFilescz = upLoadModule({
    fileList: fileListcz,
    setFileList: setFileListcz,
    draggerRef: draggerczRef,
    accept: '.rar, .zip, .jpg, .png, .pdf',
    limitNum: 20
  })

  //是否本人办理离职选项变化的回调
  const onChangeSelfHandle = (e) => {
    setSelfHandle(e.target.value)
    if (e.target.value == 1) {
      setFileListwt([])
    }
  }

  // 离职办理日期变化后赋值给工资结算日期
  const onChangeTureLeaveDate = (val) => {
    setFieldsValue({
      wagesSettleDate: val
    })
  }

  // 更新工资结算日期、实际办理离职时间、劳动合同解除协议、辞职信、工资结算时间
  const updateDetail = (field, value, callback) => {
    let key = field.charAt(0).toUpperCase() + field.slice(1);
    api['update' + key]({
      id: detailData.id,
      [field]: value,
    }).then((data) => {
      if (callback) {
        callback();
      }
      message.success('操作成功')
      detailData[field] = value;
      setDetailData(detailData)
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //退回列表页
  const onGoBack = () => {
    history.push({ pathname: '/hr/dimission', state: { goBack: 1 } })
  }

  return (
    <Spin spinning={detailLoading}>
      <div className="pro-detail">
        {
          search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: '20px',
            top: '-20px'
          }}>返回上一页</Button>
        }
        <Descriptions title="离职申请" layout="horizontal " className="pro-detail-title">
          <dItem label="离职人">{detailData.staffName}</dItem>
        </Descriptions>
        <Descriptions title="申请信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="姓名">
            <>
              {detailData.staffName}
              {oldDetailData.staffName !== detailData.staffName ? <div className="change-text">变更前：{oldDetailData.staffName}</div> : null}
            </>
          </dItem>

          <dItem label="所属公司">
            <>
              {detailData.companyName}
              {oldDetailData.companyName !== detailData.companyName ? <div className="change-text">变更前：{oldDetailData.companyName}</div> : null}
            </>
          </dItem>
          <dItem label="所属部门">
            <>
              {detailData.departmentName}
              {oldDetailData.departmentName !== detailData.departmentName ? <div className="change-text">变更前：{oldDetailData.departmentName}</div> : null}
            </>
          </dItem>
          <dItem label="职级">
            <>
              {detailData.jobPositionCn}
              {oldDetailData.jobPositionCn !== detailData.jobPositionCn ? <div className="change-text">变更前：{oldDetailData.jobPositionCn}</div> : null}
            </>
          </dItem>
          <dItem label="手机号">
            <>
              {detailDataTwo.workMobile ? detailDataTwo.workMobile : '--'}
              {oldDetailDataTwo.workMobile !== detailDataTwo.workMobile ? <div className="change-text">变更前：{oldDetailDataTwo.workMobile}</div> : null}
            </>
          </dItem>
          <dItem label="直接主管">
            <>
              {detailDataTwo.leaderName}
              {oldDetailDataTwo.leaderName !== detailDataTwo.leaderName ? <div className="change-text">变更前：{oldDetailDataTwo.leaderName}</div> : null}
            </>
          </dItem>
          <dItem label="员工编号">
            <>
              {detailDataTwo.staffNo}
              {oldDetailDataTwo.staffNo !== detailDataTwo.staffNo ? <div className="change-text">变更前：{oldDetailDataTwo.staffNo}</div> : null}
            </>
          </dItem>
          <dItem label="入职时间">
            <>
              {detailDataTwo.entryDate}
              {oldDetailDataTwo.entryDate !== detailDataTwo.entryDate ? <div className="change-text">变更前：{oldDetailDataTwo.entryDate}</div> : null}
            </>
          </dItem>
          <dItem label="离职方式">
            <>
              {detailData.leaveJobTypeCn}
              {oldDetailData.leaveJobTypeCn !== detailData.leaveJobTypeCn ? <div className="change-text">变更前：{oldDetailData.leaveJobTypeCn}</div> : null}
            </>
          </dItem>
          <dItem label="人员类型">
            <>
              {detailDataTwo.isFormalCn}
              {oldDetailDataTwo.isFormalCn !== detailDataTwo.isFormalCn ? <div className="change-text">变更前：{oldDetailDataTwo.isFormalCn}</div> : null}
            </>
          </dItem>
          <dItem label="离职报备时间">
            <>
              {detailData.leaveReportDate}
              {oldDetailData.leaveReportDate !== detailData.leaveReportDate && !detailData.isNewProcedure ? <div className="change-text">变更前：{oldDetailData.leaveReportDate}</div> : null}
            </>
          </dItem>
          <dItem label="工资结算日期">
            <>
              {detailData.wagesSettleDate ? (
                detailData.editExtendAuth ? (
                  <ControlledDatePicker
                    defaultValue={moment(detailData.wagesSettleDate, 'YYYY-MM-DD')}
                    onOk={(value, callback) =>
                      updateDetail('wagesSettleDate', value, callback)
                    }
                  />
                ) : (
                  detailData.wagesSettleDate
                )
              ) : (
                '--'
              )}
              {oldDetailData.wagesSettleDate !== detailData.wagesSettleDate && !detailData.isNewProcedure ? <div className="change-text">变更前：{oldDetailData.wagesSettleDate}</div> : null}
            </>
          </dItem>
          <dItem label="工资结算时间">
            <>
              {detailData.wagesSettleTime ? (
                detailData.editExtendAuth ? (
                  <div style={{ width: 90, overflow: 'hidden', float: 'right' }}>
                    <Select
                      style={{ width: 100, padding: 0, margin: '-5px -5px -5px -8px', color: '#aeaeaeff' }}
                      suffixIcon={<Icon type='edit' style={{ fontSize: '14px' }} />}
                      allowClear={false}
                      value={timeValue}
                      onChange={(time) => {
                        setTimeValue(time)
                        updateDetail('wagesSettleTime', time)
                      }}
                    >
                      <Option value={1}>上午</Option>
                      <Option value={2}>下午</Option>
                    </Select>
                  </div>
                ) : (
                  detailData.wagesSettleTimeCn
                )
              ) : (
                '--'
              )}
              {oldDetailData.wagesSettleTimeCn !== detailData.wagesSettleTimeCn && !detailData.isNewProcedure ? <div className="change-text">变更前：{oldDetailData.wagesSettleTimeCn}</div> : null}
            </>
          </dItem>
          <dItem label="离职最后办理时间">
            <>
              {detailData.shouldLeaveDate}
              {oldDetailData.shouldLeaveDate !== detailData.shouldLeaveDate ? <div className="change-text">变更前：{oldDetailData.shouldLeaveDate}</div> : null}
            </>
          </dItem>
          <dItem label="实际办理离职时间">
            <>
              {detailData.tureLeaveDate ? (
                detailData.editExtendAuth ? (
                  <ControlledDatePicker
                    defaultValue={moment(detailData.tureLeaveDate, 'YYYY-MM-DD')}
                    onOk={(value, callback) =>
                      updateDetail('tureLeaveDate', value, callback)
                    }
                  />
                ) : (
                  detailData.tureLeaveDate
                )
              ) : (
                '--'
              )}
              {oldDetailData.tureLeaveDate !== detailData.tureLeaveDate && !detailData.isNewProcedure ? <div className="change-text">变更前：{oldDetailData.tureLeaveDate}</div> : null}
            </>
          </dItem>
          <dItem label="交接人">
            <>
              {detailData.handoverStaffName}
              {oldDetailData.handoverStaffName !== detailData.handoverStaffName ? <div className="change-text">变更前：{oldDetailData.handoverStaffName}</div> : null}
            </>
          </dItem>
          <dItem label="离职原因" span={3}>
            <>
              {detailData.leaveJobReason}
              {oldDetailData.leaveJobReason !== detailData.leaveJobReason ? <div className="change-text">变更前：{oldDetailData.leaveJobReason}</div> : null}
            </>
          </dItem>
          <dItem label="附件" span={3}>
            <>
              {
								detailData.uploadFile && detailData.uploadFile.length > 0 ? detailData.uploadFile.map((item, i) => {
									return <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>{item.name}</a>
								}) : <div>无</div>
							}
            </>
          </dItem>
          <dItem label="备注" span={3}>
            <>
              {detailData.remark ? detailData.remark : '无'}
              {oldDetailData.remark !== detailData.remark ? <div className="change-text">变更前：{oldDetailData.remark}</div> : null}
            </>
          </dItem>
        </Descriptions>

        {
          detailData.isNewProcedure && detailData.uploadedInfo !== 0 ? <>
            <div className="line-box"></div>
            <Descriptions title="离职资料"
              layout="horizontal"
              className="pro-detail-smailtitle pro-apply"
              column={1}
            >
              <dItem label="是否本人办理离职">{detailData.isSelfHandle == 0 ? '否' : '是'}</dItem>
              <dItem label='劳动合同解除协议'>
                <AgreementFiles
                  defaultValue={terminationAgreement}
                  edit={detailData.editExtendAuth}
                  onConfirm={(value, callback) =>
                    updateDetail('terminationAgreement', value, callback)
                  }
                />
              </dItem>
              <dItem label="辞职信">
                <AgreementFiles
                  defaultValue={letterResignation}
                  edit={detailData.editExtendAuth}
                  onConfirm={(value, callback) =>
                    updateDetail('letterResignation', value, callback)
                  }
                />
              </dItem>
              <dItem label="委托书">
                {
                  powerAttorney.length > 0 ? powerAttorney.map((item, i) => {
                    return <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px'}}>{item.name}</a>
                  }) : '无'
                }
              </dItem>
              <dItem label="是否暂停使用该员工OA账号">
                {detailData.stopAccount == 0 ? '否' : '是'}
              </dItem>
              <dItem label="备注">
                {detailData.uploadedInfoDesc ? detailData.uploadedInfoDesc : '无'}
                {/* {oldDetailData.uploadedInfoDesc !== detailData.uploadedInfoDesc ? <div className="change-text">变更前：{oldDetailData.uploadedInfoDesc}</div> : null} */}
              </dItem>
            </Descriptions>
          </> : null
        }

        <div className="line-box"></div>
        <Descriptions title="离职申请流程" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                // description={`${item.persons.map((item) => item)} ${item.statusName} ${item.time}`} 
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="姓名" dataIndex="userInfo.staffName" />
          <Column title="时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="status" />
          <Column title="审批意见" dataIndex="chargeMsg" />
        </Table>
        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={() => setUpdateVisible(true)}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={debounce(() => { onTurnUp() }, 3000)}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Button hidden={!buttonAuthList.hasUploadInfoPower} type="primary" onClick={onUpload} >上传资料</Button>
          <Modal
            // title="确认驳回"
            title={turnNode()}
            visible={turnModalVisible}
            onOk={debounce(() => { onTurnApprove() }, 3000)}
            onCancel={onTurnCancel}
            footer={[
              <Button key="back" onClick={onTurnCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onTurnApprove() }, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasPassPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={debounce(() => { onApprove() }, 3000)}
            onCancel={onPassCancel}
            footer={[
              <Button key="back" onClick={onPassCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onApprove() }, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>

          {/* 补充资料 */}
          <Modal
            title="补充资料确认"
            visible={updateVisible}
            onOk={debounce(() => { onReplenish() }, 3000)}
            onCancel={() => setUpdateVisible(false)}
            footer={[
              <Button key="back" onClick={() => setUpdateVisible(false)}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onReplenish() }, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={updateVal} onChange={(e) => setUpdateVal(e.target.value)} />
            </div>
          </Modal>

          <Modal
            title="上传资料"
            visible={uploadVisible}
            width={640}
            // onOk={debounce(() => { onUploadInfo() }, 3000)}
            // onCancel={() => setUploadVisible(false)}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            footer={null}
          >
            <Form onSubmit={onUploadInfo} {...formItemLayout}>
              <FormItem label="员工编号">
                <div>{detailDataTwo.staffNo}</div>
              </FormItem>
              <FormItem label="员工姓名">
                <div>{detailData.staffName}</div>
              </FormItem>
              <FormItem label="离职方式">
                <div>{detailData.leaveJobTypeCn}</div>
              </FormItem>
              <FormItem label="是否本人办理离职">
                {getFieldDecorator('isSelfHandle', {
                  rules: [{ required: true, message: '请补充完整后提交' }]
                })(
                  <Radio.Group onChange={onChangeSelfHandle}>
                    <Radio value={1} >是</Radio>
                    <Radio value={0} >否</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem label="离职办理日期">
                {getFieldDecorator('tureLeaveDate', {
                  rules: [{ required: true, message: '请补充完整后提交' }]
                })(<DatePicker allowClear={false} style={{ width: 200 }} onChange={onChangeTureLeaveDate} />)}
              </FormItem>
              <FormItem label="工资结算日期">
                {getFieldDecorator('wagesSettleDate', {
                  rules: [{ required: true, message: '请补充完整后提交' }]
                })(<DatePicker allowClear={false} style={{ width: 200 }} />)}
              </FormItem>
              <FormItem label="工资结算时间">
                {getFieldDecorator('wagesSettleTime', {
                  initialValue: 2,
                  rules: [{ required: true, message: '请补充完整后提交' }]
                })(<Select
                  placeholder="请选择时间"
                  getPopupContainer={trigger => trigger.parentNode}
                  style={{ width: '50%' }}
                >
                  <Option value={1}>上午</Option>
                  <Option value={2}>下午</Option>
                </Select>)}
              </FormItem>
              <FormItem label={<span style={{position: 'relative'}}><span style={{ color: 'red', position: 'absolute', left: '-10px', top: '-8px' }}>*</span>劳动合同解除协议</span>} >   
                <div className="dragger-box" ref={draggerlwRef} >  
                  <Upload
                    {...uploadFileslw}
                  >
                    <Button>
                      <Icon type="upload" /> 请选择文件
                    </Button>
                    <p className="ant-upload-hint">
                      支持扩展名：.png、.jpg、.pdf、.zip 单个文件大小不超过5M，不超过20个附件
                    </p>
                  </Upload>
                </div>
              </FormItem>
              {
                leaveJobTypeCn == '主动离职' ? 
                <FormItem label={<span style={{position: 'relative'}}><span style={{ color: 'red', position: 'absolute', left: '-10px', top: '-8px' }}>*</span>辞职信</span>} >
                  <div className="dragger-box" ref={draggerczRef}>
                    <Upload
                      {...uploadFilescz}
                    >
                      <Button>
                        <Icon type="upload" /> 请选择文件
                      </Button>
                      <p className="ant-upload-hint">
                        支持扩展名：.png、.jpg、.pdf、.zip 单个文件大小不超过5M，不超过20个附件
                      </p>
                    </Upload>
                  </div>
                </FormItem> : null
              }
              {
                selfHandle == 0 ? <FormItem label={<span style={{position: 'relative'}}><span style={{ color: 'red', position: 'absolute', left: '-10px', top: '-8px' }}>*</span>委托书</span>} >
                  <div className="dragger-box" ref={draggerwtRef}>
                  
                    <Upload
                      {...uploadFileswt}
                    >
                      <Button>
                        <Icon type="upload" /> 请选择文件
                      </Button>
                      <p className="ant-upload-hint">
                        支持扩展名：.png、.jpg、.pdf、.zip 单个文件大小不超过5M，不超过20个附件
                      </p>
                    </Upload>
                  </div>
                </FormItem> : null
              }
              <FormItem label="是否暂停使用该员工OA账号">
                {getFieldDecorator('stopAccount', {
                  rules: [{ required: true, message: '请补充完整后提交' }]
                })(
                  <Radio.Group>
                    <Radio value={1} >是</Radio>
                    <Radio value={0} >否</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem label="备注">
                {getFieldDecorator('uploadedInfoDesc')(
                  <TextArea />
                )}
              </FormItem>
              <BtnGroup cancelName="取消" confirmName="确认" onConfirm={onUploadInfo} onCancel={() => setUploadVisible(false)} />
            </Form>
          </Modal>
        </div>
      </div>
    </Spin>
  )
}

export default Form.create()(DimDetail);
