/**
 * 模块名称: 离职管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dimission from './Dimission'
import DimDetail from './DimDetail'
import './index.scss'
const RecruitIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/detail'} component={DimDetail} />
        <Route component={Dimission} />
      </Switch>
    </div>
  )
}

export default RecruitIndex