/**
 * 模块名称: 员工信息管理
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
// import ReactDOM from 'react-dom'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Icon,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
  message,
  Select,
  Radio,
  Drawer,
  Button,
  Row,
  Col,
  Spin
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import CorpMore from 'components/CorpMore'
import StaffInfoFilter from '@/components/StaffInfoFilter'
import Auth from '@/components/AuthMiddleware'
import Export from '@/components/Export'
import Import from '@/components/Import'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { Option } = Select
const FormItem = Form.Item
let pageSize = 10
let currentPage = 1

let filter = {
  name: '',
  companyId: '',
}

//保存筛选初始值
let params = {

}

let allFilter = {}

let selectedIds = ''

//筛选初始值
let exporfilter = {
  staffName: '',
  workEmail: '',
  workMobile: '',
  departmentId: '',
  jobPosition: '',
  groupId: '',
  leaderName: '',
  isOnJob: '',
  isFormal: '',
  attendanceNum: '',
  identityCardNoFace: '',
  userName: '',
  sCorel: '',
  entryDateMin: '',
  entryDateMax: '',
  eaveDateMin: '',
  leaveDateMax: '',
  addTimeMin: '',
  addTimeMax: '',
  leaveApplyDateMin: '',
  leaveApplyDateMax: ''
}

const Employee = (props) => {
  const productsRef = useRef()
  const ImportRef = useRef()
  const ImportAgeRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  let locationPathname = location.pathname

  const search = parseSearch(location.search)

  const [loading, setLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [checked, setChecked] = useState(0)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible4, setVisible4] = useState(false)
  const [attendanceTimezoneShow, setAttendanceTimezoneShow] = useState([])
  const [visible, setVisible] = useState(false)
  const [staffJobLevel, setStaffJobLevel] = useState([])
  const [batchCorp, setBatchCorp] = useState([])
  const [batchDepartment, setBatchDepartment] = useState([])
  const [checkedId, setCheckedId] = useState('')
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [companyValue, setCompanyValue] = useState(undefined)
  const [authList, setAuthList] = useState([])
  const [visibleFile, setVisibleFile] = useState(false)
  const [visibleAgeFile, setVisibleAgeFile] = useState(false)
  const [disabledDepartment, setdisabledDepartment] = useState(true)
  const [showExport, setShowExport] = useState(false)
  const [exportMethod, setExportMethod] = useState('')
  // const [ids, setIds] = useState([])
  const [imSection, setImSection] = useState(0)
  const [annualleaveDaysVisible, setAnnualleaveDaysVisible] = useState(false)
  const [staffInfo, setStaffInfo] = useState({})
  const [annualDetail, setAnnualDetail] = useState([])

  useEffect(() => {
    filter = {
      name: '',
      companyId: ''
    }
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      initExFilter()
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
    }
    getEmployeeList() //员工信息列表接口
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //员工信息列表
  const getCorpList = () => {
    setLoading(true)
    api.getManpower({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      name: filter.name
    }).then(data => {
      setList(data.list)
      // data.ids && setIds(data.ids)
      if(currentPage === 1){
        if(data.ids){
          localStorage.setItem('ids', JSON.stringify(data.ids))
        }else{
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setChecked(0)
      setCheckedIdArr([])
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //筛选时调接口
  const getEmployeeList = () => {
    setLoading(true)
    api.getManpower({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      name: filter.name,
      ...exporfilter
    }).then(data => {
      setList(data.list)
      // setIds(data.ids)
      if(currentPage === 1){
        if(data.ids){
          localStorage.setItem('ids', JSON.stringify(data.ids))
        }else{
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      //setChecked(0)
      //setCheckedIdArr([])
      // if (data.ids.length > 0) setIds(data.ids)
      // setChecked(0)
      // setCheckedIdArr([])
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击时筛选
  const formFilter = (vals) => {
    //const values = Object.values(vals)
    currentPage = 1
    history.replace(match.path)
    exporfilter.staffNo = vals.staffNo || vals.staffNo === 0 ? vals.staffNo : ''
    exporfilter.staffName = vals.staffName || vals.staffName === 0 ? vals.staffName : ''
    exporfilter.workEmail = vals.workEmail || vals.workEmail === 0 ? vals.workEmail : ''
    exporfilter.workMobile = vals.workMobile || vals.workMobile === 0 ? vals.workMobile : ''
    exporfilter.departmentId = vals.departmentId || vals.departmentId === 0 ? vals.departmentId : ''
    exporfilter.jobPosition = vals.jobPosition || vals.jobPosition === 0 ? vals.jobPosition : ''
    exporfilter.groupId = vals.groupId || vals.groupId === 0 ? vals.groupId : ''
    exporfilter.leaderName = vals.leaderName || vals.leaderName === 0 ? vals.leaderName : ''
    exporfilter.isOnJob = vals.isOnJob || vals.isOnJob === 0 ? vals.isOnJob == -1 ? '' :  vals.isOnJob : ''
    exporfilter.isFormal = vals.isFormal || vals.isFormal === 0 ? vals.isFormal : ''
    exporfilter.attendanceNum = vals.attendanceNum || vals.attendanceNum === 0 ? vals.attendanceNum : ''
    exporfilter.identityCardNoFace = vals.identityCardNoFace || vals.identityCardNoFace === 0 ? vals.identityCardNoFace : ''
    exporfilter.userName = vals.userName || vals.userName === 0 ? vals.userName : ''
    exporfilter.isCorel = vals.isCorel || vals.isCorel === 0 ? vals.isCorel : ''
    exporfilter.entryDateMin = vals.entryDate || vals.entryDate === 0 ? vals.entryDate[0].format('YYYY-MM-DD') : ''
    exporfilter.entryDateMax = vals.entryDate || vals.entryDate === 0 ? vals.entryDate[1].format('YYYY-MM-DD') : ''
    exporfilter.leaveDateMin = vals.leaveDate || vals.leaveDate === 0 ? vals.leaveDate[0].format('YYYY-MM-DD') : ''
    exporfilter.leaveDateMax = vals.leaveDate || vals.leaveDate === 0 ? vals.leaveDate[1].format('YYYY-MM-DD') : ''
    exporfilter.addTimeMin = vals.addTime || vals.addTime === 0 ? vals.addTime[0].format('YYYY-MM-DD') : ''
    exporfilter.addTimeMax = vals.addTime || vals.addTime === 0 ? vals.addTime[1].format('YYYY-MM-DD') : ''
    exporfilter.leaveApplyDateMin = vals.leaveApplyDate || vals.leaveApplyDate === 0 ? vals.leaveApplyDate[0].format('YYYY-MM-DD') : ''
    exporfilter.leaveApplyDateMax = vals.leaveApplyDate || vals.leaveApplyDate === 0 ? vals.leaveApplyDate[1].format('YYYY-MM-DD') : ''
    exporfilter.isMainAccount = vals.isMainAccount || vals.isMainAccount === 0 ? vals.isMainAccount : ''
    getEmployeeList()
    setChecked(0)
    setCheckedIdArr([])
  }

  //保存筛选
  const saveFilterTerm = (vals) => {
    const values = Object.values(vals)
    if (values.some(item => item !== undefined)) {
      params = {}
      for (let k in vals) {//eslint-disable-line
        if (vals[k] !== undefined) {
          if (k === 'entryDate' || k === 'leaveDate' || k === 'addTime' || k === 'leaveApplyDate') {
            params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
          } else {
            params[k] = vals[k]
          }
        }
      }
      setVisible(true)
    } else {
      message.error('当前没有设置筛选条件')
    }
  }

  //点击保存筛选弹出框内确定按钮
  const handleOk = (filterInfoValue) => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  //点击保存筛选弹出框内取消按钮
  const handleCancel = () => {
    setVisible(false)
  }

  //重置
  const onFilterReset = () => {
    initExFilter()
    currentPage = 1
    history.replace(match.path)
    getEmployeeList()
  }
  //初始化筛选项
  const initExFilter = () => {
    exporfilter = {
      staffNo: '',
      staffName: '',
      workEmail: '',
      workMobile: '',
      departmentId: '',
      jobPosition: '',
      groupId: '',
      leaderName: '',
      isOnJob: '',
      isFormal: '',
      attendanceNum: '',
      identityCardNoFace: '',
      userName: '',
      isCorel: '',
      entryDateMin: '',
      entryDateMax: '',
      leaveDateMin: '',
      leaveDateMax: '',
      addTimeMin: '',
      addTimeMax: '',
      leaveApplyDateMin: '',
      leaveApplyDateMax: '',
      leaveApplyDateMax: '',
      isMainAccount: ''
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      //setCheckedId(checkedIdArr.join(","))
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
      // setChecked(arr.length)
      // selectedIds = arr
    },
    selectedRowKeys: checkedIdArr
  }
  //反选
  const onReverse = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i].id
    }
    let filterIds = []
    arr.forEach(item => {
      if (!checkedIdArr.includes(item)) {
        filterIds.push(item)
      }
    })
    setCheckedIdArr(filterIds)
    setCheckedId(filterIds.join(","))
    setChecked(filterIds.length)
    selectedIds = filterIds
  }
  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i].id
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    setCheckedId(arr.join(","))
    //console.log(arr)
    selectedIds = arr
  }

  //员工异动
  const onVariation = () => {
    history.push('/hr/employee/staffAlteration')
  }

  //导入
  const onImportFile = () => {
    setVisibleFile(true)
    // onRemoved就是子组件暴露给父组件的方法
    ImportRef.current.onRemoved()
  }

  const onCloseImport = () => {
    setVisibleFile(false)
  }

  const onSearch = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.getImportExcel({
          path: fileList[0].url
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportRef.current.onLoading()
          setVisibleFile(false)
        }).catch(data => {
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  //导入工龄
  const onImportFileAge = () => {
    setVisibleAgeFile(true)
    ImportAgeRef.current.onRemoved()
  }

  const onSearchAge = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.importStaffSociologyWorkAge({
          filePath: fileList[0].url
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportAgeRef.current.onLoading()
          setVisibleAgeFile(false)
          // window.location.reload()
        }).catch(data => {
          ImportAgeRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  //导出
  const onExportFile = (method) => {
    setShowExport(true)
    setExportMethod(method)
    Object.assign(allFilter, filter, exporfilter)
    //console.log(allFilter)
  }

  //筛选
  const onCorpFilter = (data) => {
    data.length ? setImSection(imSection + 1) : setImSection(0)
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getCorpList()

  }

  const onChangeTable = (pagination, filters, sorter) => {
    //console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  //批量操作
  const onClick = ({ key }) => {
    if (key === '1') {
      api.getCommon({ type: 'attendance_timezone_show' }).then(data => setAttendanceTimezoneShow(data)).catch(() => setLoading(false))
      setVisible1(true)
      setFieldsValue({
        id: 1
      })
    } else if (key === '2') {
      api.getSelectJobStationLevelList({ limit: global.paramsLimit }).then(data => setStaffJobLevel(data.list))
      setVisible2(true)
    } else if (key === '3') {
      api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setBatchCorp(data.list)).catch(() => setLoading(false))
      setVisible3(true)
    } else if (key === '4') {
      api.downStfPic({ id: checkedIdArr }).then(data => {
        window.location = `/${data}`
      })
    } else {
      message.error('请选择批量操作内容')
    }
  }

  //批量考勤时区设置确定
  const batchHandleOk1 = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        if (vals.id == undefined) {//eslint-disable-line
          message.error('考勤时区不可为空。')

        } else {
          api.saveUpdateAttendanceTimezone({
            id: checkedId,
            attendanceTimezone: vals.id
          }).then(data => {
            setVisible1(false)
            message.success('修改成功')
            getCorpList()
            setFieldsValue({
              id: undefined,
              jobStation: undefined,
              departmentId: undefined
            })
          })
        }
      }
    })
  }

  //批量考勤时区设置取消
  const batchHandleCancel1 = e => {
    setFieldsValue({
      id: undefined,
      jobStation: undefined,
      departmentId: undefined
    })
    setVisible1(false)
  }

  //批量调整岗位级别确定
  const batchHandleOk2 = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        api.saveUpdateJobStation({
          id: checkedId,
          jobStation: vals.jobStation ? vals.jobStation : ''
        }).then(data => {
          message.success('修改成功')
          setVisible2(false)
          getCorpList()
          setFieldsValue({
            id: undefined,
            jobStation: undefined,
            departmentId: undefined
          })
        })
      }
    })
  }

  //批量调整岗位级别取消
  const batchHandleCancel2 = e => {
    setFieldsValue({
      id: undefined,
      jobStation: undefined,
      departmentId: undefined
    })
    setVisible2(false)
  }

  //批量部门调整确定
  const batchHandleOk3 = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (vals.departmentId) {
        if (!err) {
          api.saveUpdateDepartment({
            id: checkedId,
            departmentId: vals.departmentId
          }).then(data => {
            message.success('修改成功')
            setVisible3(false)
            getCorpList()
            setCompanyValue(undefined)
            setFieldsValue({
              id: undefined,
              jobStation: undefined,
              departmentId: undefined
            })
          })
        }
      } else {
        message.error('请选择内容')
      }

    })
  }

  //批量部门调整取消
  const batchHandleCancel3 = e => {
    setFieldsValue({
      id: undefined,
      jobStation: undefined,
      departmentId: undefined
    })
    setCompanyValue(undefined)
    setVisible3(false)
    setdisabledDepartment(true)
  }

  //批量部门调整逻辑
  const onBatchCompany = (value) => {
    setCompanyValue(value)
    setdisabledDepartment(false)
    api.getSelectDepartmentList({
      companyId: value,
      limit: global.paramsLimit
    }).then(data => {
      setBatchDepartment(data.list)
      setFieldsValue({
        departmentId: undefined
      })
    }).catch(() => setLoading(false))
  }

  const onBatchOperation = () => {
    if (checked === 0) {
      message.error('请选择批量操作内容')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //批量设置
  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="1" >
        <Auth auths={authList} code="staff-attendance-zone-set">
          考勤时区设置
        </Auth>
      </Menu.Item>
      <Menu.Item key="2" >
        <Auth auths={authList} code="staff-change-job-level">
          调整岗位级别
        </Auth>
      </Menu.Item>
      <Menu.Item key="3" >
        <Auth auths={authList} code="staff-department-change">
          部门调整
        </Auth>
      </Menu.Item>
      <Menu.Item key="4" >
        <Auth auths={authList} code="staff-pic-batch-download">
          入职照片下载
        </Auth>
      </Menu.Item>
    </Menu >
  )

  const BatchHint = (
    <Menu onClick={onClick}>
      <Menu.Item key="0" >请选择批量操作内容</Menu.Item>
    </Menu>
  )

  const onQrcode = () => setVisible4(true)

  const downloadQrcode = () => {
    const oA = document.createElement("a")
    oA.download = '扫码入职二维码.png' // 设置下载的文件名，默认是'下载'
    oA.href = '/upload/qrcode-joinjob.png'
    document.body.appendChild(oA)
    oA.click()
    oA.remove() // 下载之后把创建的元素删除
  }

  //年假明细
  const annualleaveDays = (id) => {
    setformLoading(true)
    setAnnualleaveDaysVisible(true)
    api.setStaffAnnualDetail({ staffId: id }).then(res => {
      setStaffInfo(res.staffInfo)
      setAnnualDetail(res.annualDetail)
      setformLoading(false)
    })
  }

  return (
    <div className="mployee-wrap">
      <CorpMore onChange={onCorpFilter} />
      <StaffInfoFilter
        formFilter={formFilter}
        saveFilterTerm={saveFilterTerm}
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={visible}
        locationPathname={locationPathname}
        onFilterReset={onFilterReset}
        section={imSection}
        companyId={filter.companyId}
        exporfilter={exporfilter}
        state={history.location.state}
      />
      <div className="line"></div>
      <div className="add-wrap">
        <Dropdown overlay={checked === 0 ? BatchHint : menu}>
          <span className="ant-dropdown-link">
            <Button type="primary" icon="align-left" onClick={onBatchOperation}>批量操作</Button>
          </span>
        </Dropdown>
        <Modal
          title='考勤时区设置'
          visible={visible1}
          onOk={batchHandleOk1}
          onCancel={batchHandleCancel1}
        >
          <Form {...formItemLayout}>
            <FormItem>
              {getFieldDecorator('id')(<Radio.Group style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {
                  attendanceTimezoneShow.map((v, i) => {
                    return <Radio.Button style={{ width: '46%', margin: '5px' }} value={v.id} key={i}>{v.name}</Radio.Button>
                  })
                }
              </Radio.Group>)}
            </FormItem>
          </Form>
        </Modal>
        <Modal
          title='调整岗位级别'
          visible={visible2}
          onOk={batchHandleOk2}
          onCancel={batchHandleCancel2}
        >
          <Form  {...formItemLayout}>
            <FormItem label="岗位级别">
              {getFieldDecorator('jobStation')(<Select style={{ width: 180 }} placeholder="岗位级别">
                {
                  staffJobLevel.map((v, i) => {
                    return <Option value={v.id} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
          </Form>
        </Modal>
        <Modal
          title='部门调整'
          visible={visible3}
          onOk={batchHandleOk3}
          onCancel={batchHandleCancel3}
        >
          <Form {...formItemLayout} >
            <FormItem label="公司">
              <Select style={{ width: 180 }} value={companyValue} placeholder="公司名称" onSelect={onBatchCompany}>
                {
                  batchCorp.map((v, i) => {
                    return <Option value={v.id} key={i}>{v.name}</Option>
                  })
                }
              </Select>
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('departmentId')(<Select style={{ width: 180 }} placeholder="所属部门" disabled={disabledDepartment} showSearch={true} optionFilterProp="children">
                {
                  batchDepartment.map((v, i) => {
                    return <Option value={v.id} key={v.id}>{v.name}</Option>

                  })
                }
              </Select>)}
            </FormItem>
          </Form>
        </Modal>
        <Modal
          title="入职二维码"
          visible={visible4}
          onOk={downloadQrcode}
          okText="下载二维码"
          onCancel={() => setVisible4(false)}
          className="qrcode-download-modal"
        >
          <div className="qrcode-super">
            <img 
              className="qrcode-img" 
              src="/upload/qrcode-joinjob.png" 
            />
          </div>
        </Modal>
        <Auth auths={authList} code="staff-qrcode-download">
          <Button type="primary" icon="qrcode" onClick={onQrcode} style={{ marginLeft: 15 }}>入职二维码</Button>
        </Auth>
        <Auth auths={authList} code="staff-change">
          <Button type="primary" icon="user-add" onClick={onVariation} style={{ marginLeft: 15 }}>员工异动</Button>
        </Auth>

        {/* 导入社会工龄 */}
        <Auth auths={authList} code="import-work-age">
          <Button type="primary" icon="download" onClick={onImportFileAge} style={{ marginLeft: 15 }}>导入工龄</Button>
          <Import
            onSearch={onSearchAge}
            visibleFile={visibleAgeFile}
            onCloseImport={() => setVisibleAgeFile(false)}
            ImportTemplate="/api/File/DownTemplate?url=/templates/importSociologyWorkAge.xlsx&name=导入社会工龄模板"
            ImportRef={ImportAgeRef}
            accept='.xlsx'
          />
        </Auth>
        <Auth auths={authList} code="import">
          <Button type="primary" icon="download" onClick={onImportFile} style={{ marginLeft: 15 }}>导入</Button>
          <Import
            onSearch={onSearch}
            visibleFile={visibleFile}
            onCloseImport={onCloseImport}
            ImportTemplate="/api/File/DownTemplate?url=/templates/staff.xlsx&name=员工信息管理导入模板"
            ImportRef={ImportRef}
          />
        </Auth>
        <Auth auths={authList} code="export">
          <Button type="primary" icon="upload" onClick={() => onExportFile('export')} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="xz-export">
          <Button type="primary" icon="upload" onClick={() => onExportFile('administrationExport')} style={{ marginLeft: 15 }}>行政导出</Button>
        </Auth>
        <Auth auths={authList} code="add">
          <Link to={`${match.path}/staffAdd`} style={{ cursor: 'pointer' }}>
            <Button type="primary" icon="plus" style={{ marginLeft: 15 }}>添加</Button>
          </Link>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          {checked !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {checked == 0 //eslint-disable-line
            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>
        </>
      } type="info" showIcon />

      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2700 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}

      >
        <Column title="ID" dataIndex="id" fixed="left" width={80} />
        <Column title="员工编号" key="staffNo" fixed="left" width={160} render={(text, record) => (
          <div>{text.staffNo === 0 ? '--' : text.staffNo}</div>
        )} />
        <Column title="公司用名" key="staffName" fixed="left" width={140} render={(text, record) => (
          text.operateAuth.indexOf("browse-detail") !== -1 ?
            <Auth auths={text.operateAuth} code="browse-detail">
              <Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer', color: '#1890FF' }}>{text.staffName}</Link>
            </Auth>
            : <div>{text.staffName}</div>
        )} />
        <Column title="是否主账号" key="isMainAccount" width={170} render={(text, record) => (
          <div>{text.isMainAccount === 1 ? '是' : '否'}</div>
        )} />
        <Column title="考勤号" dataIndex="attendanceNum" width={120} />
        <Column title="公司邮箱" dataIndex="workEmail" width={300} />
        <Column title="身份证号" dataIndex="identityCardNo" width={300} />
        <Column title="试用期" dataIndex='isFormalCn' width={100} />
        <Column title="实习期" dataIndex="isPracticelCn" width={100} />
        <Column title="所属公司" dataIndex="companyName" width={100} />
        <Column title="所属事业群" dataIndex="companyGroupName" width={135} />
        <Column title="所属部门" dataIndex="departmentName" width={150} />
        <Column title="直接主管" dataIndex="leaderName" width={120} />
        <Column title="岗位级别" dataIndex="jobStationCn" width={120} />
        <Column title="职级" dataIndex="jobPositionCn" width={120} />
        <Column title="到职日期" dataIndex="entryDate" width={200} render={(text, record) => (
          <div>{text.slice(0, 10)}</div>
        )} />
        <Column title="离职日期" dataIndex="leaveDate" width={150} render={(text, record) => (
          <div>{text.slice(0, 10)}</div>
        )} />
        <Column title="员工状态" dataIndex="isOnJobCn" width={120} />
        <Column title="年假" dataIndex="annualleaveDays" width={80} render={(text, record) => (
          <>{authList.some(v => v == 'annual-detail') ? <a onClick={() => annualleaveDays(record.id)}>{text}</a> : <div>{text}</div>}</>
        )} />
        <Column title="考勤时区" dataIndex="attendanceTimezoneShowCn" width={120} render={(text, record) => (
          <div>{text.slice(0, 1)}</div>
        )} />
        <Column title="操作" key="set" fixed="right" width={80} render={(text, record) => (
          <>
            {
              text.operateAuth.indexOf("browse-detail") !== -1 &&
                <Auth auths={text.operateAuth} code="browse-detail">
                  <Tooltip title="在新页面打开详情页" placement="bottom">
                    <Link target='_blank' to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer', color: '#1890FF' }}>
                      <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginRight: '8px' }} />
                    </Link>
                  </Tooltip>
                </Auth>
            }
            <Auth auths={text.operateAuth} code="edit">
              <Tooltip title="编辑" placement="bottom">
                <Link to={`${match.path}/staffEdit?id=${record.id}`} style={{ cursor: 'pointer' }}>
                  <Icon type="edit" style={{ color: '#1890FF' }} />
                </Link>
              </Tooltip>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title="年假明细"
        placement="right"
        width="800"
        onClose={() => setAnnualleaveDaysVisible(false)}
        visible={annualleaveDaysVisible}
      >
        <div style={{ marginBottom: '80px' }}>
          <Spin spinning={formLoading}>
            <Row gutter={8} style={{ marginBottom: '15px' }}>
              <Col span={8}>
                姓名： {staffInfo.staffName}
              </Col>
              <Col span={4}></Col>
              <Col span={8}>
                部门：{staffInfo.departmentName}
              </Col>
            </Row>
            <Row gutter={8} style={{ marginBottom: '30px' }}>
              <Col span={8}>
                员工编号： {staffInfo.staffNo}
              </Col>
              <Col span={4}></Col>
              <Col span={8}>
                直属上级：{staffInfo.leaderName}
              </Col>
            </Row>
            <Table
              dataSource={annualDetail}
              rowKey="id"
              pagination={false}
              className="today-info"
            >
              <Column dataIndex="typeName" title="年假类型" />
              <Column dataIndex="changeNum" title="时长" />
              <Column dataIndex="lastRemainNum" title="剩余时长" render={(text, record) => (
                <div>去年{record.lastRemainNum} 今年{record.nowRemainNum}</div>
              )} />
              <Column dataIndex="updateTime" title="时间" />
            </Table>
          </Spin>
        </div>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={() => setAnnualleaveDaysVisible(false)} type="primary">关闭</Button>
        </div>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getstaffExportTpl"
        fieldsUrl="getstaffTplItems"
        saveUrl="savestaffExportTpls"
        exportUrl="exportStaff"
        method={exportMethod}
        parame={{ ExportType: 'staff', id: selectedIds, ...allFilter }}
        cRef={productsRef}
      />
    </div>
  )
}

export default Form.create()(Employee)