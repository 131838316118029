/**
 * 模块名称: 员工信息详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Tabs,
  Descriptions,
  Table,
  Button,
  message,
  Spin
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'
import PreviewImage from '../components/PreviewImage'

const { TabPane } = Tabs
const { Column } = Table

const Details = (props) => {
  const { history } = props
  const search = parseSearch(props.location.search)

  const [detailLoading, setDetailLoading] = useState(true)
  const [detailData, setDetailData] = useState({})
  const [detailList, setDetailList] = useState([])
  const [workList, setWorkList] = useState([])
  const [familyList, setFamilyList] = useState([])

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      setDetailLoading(false)
      api.getStaffDetail({ id: search.id }, true).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setDetailData(data)
        if (data.workExperience) {
          setWorkList(JSON.parse(data.workExperience))
        }
        if (data.familyMembers) {
          setFamilyList(JSON.parse(data.familyMembers))
        }
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  const onChangeTable = () => {
    api.getStaffAlteration({ staffId: detailData.id }, true).then(data => {
      setDetailList(data.list)
    })
  }

  //退回列表页
  const onGoBack = () => {
    history.push({pathname:'/hr/employee',state: { goBack: 1}})
  }

  return (
    <Spin spinning={detailLoading}>
      <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <span>{detailData.staffName}</span>
        {search.new && search.new == 1 && <Button onClick={onGoBack} type="primary">返回上一页</Button>}
      </h1>
      <Tabs defaultActiveKey="1" onChange={onChangeTable} >
        <TabPane tab="基本信息" key="1">
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="个人信息" layout="vertical">
              <Descriptions.Item label="真实姓名">{detailData.trueName}</Descriptions.Item>
              <Descriptions.Item label="手机号">{detailData.personalMobile}</Descriptions.Item>
              <Descriptions.Item label="身份证号码">{detailData.identityCardNo}</Descriptions.Item>
              <Descriptions.Item label="性别" >{detailData.sexCn}</Descriptions.Item>
              <Descriptions.Item label="出生日期">{detailData.birth}</Descriptions.Item>
              <Descriptions.Item label="出生月份" >{detailData.birthmonth}</Descriptions.Item>
              <Descriptions.Item label="年龄" >{detailData.age}</Descriptions.Item>
              <Descriptions.Item label="婚姻状况">{detailData.marriageStatusCn}</Descriptions.Item>
              <Descriptions.Item label="政治面貌">
                {detailData.politicCountenance == 5 ? <>
                  <span>{detailData.politicCountenanceCn}</span>
                  <span style={{ marginLeft: '15px'}}>{detailData.politicCounOther}</span>
                </> : detailData.politicCountenanceCn ? detailData.politicCountenanceCn : '--'}
              </Descriptions.Item>
              <Descriptions.Item label="招聘渠道">{detailData.recruitmentSourceCn}</Descriptions.Item>
              <Descriptions.Item label="介绍人">{detailData.introducerName ? detailData.introducerName : '--'}</Descriptions.Item>
              <Descriptions.Item label="个人邮箱">{detailData.personalEmail ? detailData.personalEmail : '--'}</Descriptions.Item>
              <Descriptions.Item label="现住址">{detailData.address ? detailData.address : '--'}</Descriptions.Item>
              <Descriptions.Item label="是否有职称证书">{detailData.isHasTitleCertCn ? detailData.isHasTitleCertCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="证书等级">{detailData.certLevel ? detailData.certLevel : '--'}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="学历信息" layout="vertical">
              <Descriptions.Item label="最高学历">{detailData.educationLevelCn}</Descriptions.Item>
              <Descriptions.Item label="专业类型">{detailData.majorTypeCn ? detailData.majorTypeCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="所学专业">{detailData.majorName ? detailData.majorName : '--'}</Descriptions.Item>
              <Descriptions.Item label="毕业院校">{detailData.schoolName}</Descriptions.Item>
              <Descriptions.Item label="毕业时间">{detailData.graduateTime ? detailData.graduateTime : '--'}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="工作信息" layout="vertical">
              <Descriptions.Item label="首次参加工作时间">{detailData.firstWorkTime}</Descriptions.Item>
            </Descriptions>
            <div>
              <div style={{ paddingBottom: 10 }}>工作经历</div>
              <Table
                dataSource={workList}
                rowKey={record => JSON.stringify(record)}
                pagination={false}
              >
                <Column title="开始时间" dataIndex="startTime" />
                <Column title="结束时间" dataIndex="endTime" />
                <Column title="单位名称" dataIndex="company" />
                <Column title="职务" dataIndex="position" />
                <Column title="证明人" dataIndex="witness" />
                <Column title="联系方式" dataIndex="mobile" />
                <Column title="离职原因" dataIndex="leavReason" />
              </Table>
            </div>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="户口信息" layout="vertical">
              <Descriptions.Item label="户口所在地">{detailData.residenceAddress}</Descriptions.Item>
              <Descriptions.Item label="户口性质">{detailData.residenceTypeCn}</Descriptions.Item>
              <Descriptions.Item label="身份证地址">{detailData.identityAddress}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="工资卡信息" layout="vertical">
              <Descriptions.Item label="银行名称">{detailData.bankName ? detailData.bankName : '--'}</Descriptions.Item>
              <Descriptions.Item label="银行卡号">{detailData.bankNo ? detailData.bankNo : '--'}</Descriptions.Item>
              <Descriptions.Item label="银行卡户名">{detailData.bankUserName ? detailData.bankUserName : '--'}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="紧急联系人信息" layout="vertical">
              <Descriptions.Item label="紧急联系人姓名">{detailData.emergencyContactName}</Descriptions.Item>
              <Descriptions.Item label="紧急联系人与本人关系">{detailData.emergencyContactType}</Descriptions.Item>
              <Descriptions.Item label="紧急联系人联系方式">{detailData.emergencyContactTel}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="家庭信息" layout="vertical">
              <Descriptions.Item label="子女个数">{detailData.childrenNum}</Descriptions.Item>
              <Descriptions.Item label="配偶姓名">{detailData.spouseName ? detailData.spouseName : '--'}</Descriptions.Item>
              <Descriptions.Item label="配偶年龄">{detailData.spouseAge ? detailData.spouseAge : '--'}</Descriptions.Item>
              <Descriptions.Item label="配偶工作单位">{detailData.spouseCompany ? detailData.spouseCompany : '--'}</Descriptions.Item>
              <Descriptions.Item label="配偶职位">{detailData.spousePosition ? detailData.spousePosition : '--'}</Descriptions.Item>
              <Descriptions.Item label="配偶联系方式">{detailData.spouseMobile ? detailData.spouseMobile : '--'}</Descriptions.Item>
            </Descriptions>
            <div>
              <div style={{ paddingBottom: 10 }}>家庭成员</div>
              <Table
                dataSource={familyList}
                rowKey={record => JSON.stringify(record)}
                pagination={false}
              >
                <Column title="姓名" dataIndex="name" />
                <Column title="与本人关系" dataIndex="relationship" />
                <Column title="年龄" dataIndex="age" />
                <Column title="工作单位" dataIndex="company" />
                <Column title="职位" dataIndex="position" />
                <Column title="联系方式" dataIndex="mobile" />
              </Table>
            </div>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="行业信息" layout="vertical">
              <Descriptions.Item label="近亲属是否在本公司就职">{detailData.isHasRelativesCn ? detailData.isHasRelativesCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="就职本公司近亲属姓名" span={2}>{detailData.relativesName ? detailData.relativesName : '--'}</Descriptions.Item>
              <Descriptions.Item label="近亲属是否在同行业就职">{detailData.reltIsSameIndustryCn ? detailData.reltIsSameIndustryCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="就职同行业近亲属姓名">{detailData.samIndReltName ? detailData.samIndReltName : '--'}</Descriptions.Item>
              <Descriptions.Item label="近亲属就职同行业公司名称">{detailData.samIndReltCompany ? detailData.samIndReltCompany : '--'}</Descriptions.Item>
              <Descriptions.Item label="近亲属就职同行业岗位">{detailData.samIndReltPostion ? detailData.samIndReltPostion : '--'}</Descriptions.Item>
              <Descriptions.Item label="近亲属就职同行业入职时间" span={2}>{detailData.samIndReltWorkTime ? detailData.samIndReltWorkTime : '--'}</Descriptions.Item>
              <Descriptions.Item label="本人是否在其他单位担任法人、监事等职位">{detailData.isOthCompLegalPersonCn ? detailData.isOthCompLegalPersonCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="在其它公司任岗信息">{detailData.othCompPostion ? detailData.othCompPostion : '--'}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="身份信息" layout="vertical">
              <Descriptions.Item label="个人白底照片">
                {detailData.userPic ? <PreviewImage src={detailData.userPic} /> : '--'}
              </Descriptions.Item>
              <Descriptions.Item label="身份证人像面">
                {detailData.idtPicPositive ? <PreviewImage src={detailData.idtPicPositive} /> : '--'}
              </Descriptions.Item>
              <Descriptions.Item label="身份证国徽面">
                {detailData.idtPicBack ? <PreviewImage src={detailData.idtPicBack} /> : '--'}
              </Descriptions.Item>
              <Descriptions.Item label="离职证明">
                {detailData.leavCert ? <PreviewImage src={detailData.leavCert} /> : '--'}
              </Descriptions.Item>
              <Descriptions.Item label="毕业证">
                {detailData.diploma ? <PreviewImage src={detailData.diploma} /> : '--'}
              </Descriptions.Item>
              <Descriptions.Item label="银行卡">
                {detailData.bankCard ? <PreviewImage src={detailData.bankCard} /> : '--'}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="岗位信息" layout="vertical">
              <Descriptions.Item label="所属公司">{detailData.companyName}</Descriptions.Item>
              <Descriptions.Item label="所属部门">{detailData.departmentName}</Descriptions.Item>
              <Descriptions.Item label="所属事业群">{detailData.companyGroupName ? detailData.companyGroupName : '--'}</Descriptions.Item>
              <Descriptions.Item label="职级">{detailData.jobPositionCn}</Descriptions.Item>
              <Descriptions.Item label="直接主管">{detailData.leaderName}</Descriptions.Item>
              <Descriptions.Item label="员工类型">{detailData.staffTypeCn ? detailData.staffTypeCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="试用期考核级别">{detailData.prbtAsmLevel ? detailData.prbtAsmLevel : '--'}</Descriptions.Item>
              <Descriptions.Item label="转正后考核级别">{detailData.formAsmLevel ? detailData.formAsmLevel : '--'}</Descriptions.Item>
              <Descriptions.Item label="职位中文名称">{detailData.positionName ? detailData.positionName : '--'}</Descriptions.Item>
              <Descriptions.Item label="岗位级别">{detailData.jobStationCn ? detailData.jobStationCn : '--'}</Descriptions.Item>
              <Descriptions.Item label="实习">{detailData.isPracticelCn}</Descriptions.Item>
              <Descriptions.Item label="实习管理">{detailData.isPracticelManagerCn}</Descriptions.Item>
              <Descriptions.Item label="管理层">{detailData.isManagerCn ? detailData.isManagerCn : '--'}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="入职离职时间" layout="vertical">
              <Descriptions.Item label="到职日期">{detailData.entryDate}</Descriptions.Item>
              <Descriptions.Item label="转正日期">{detailData.formalDate}</Descriptions.Item>
              <Descriptions.Item label="提前转正日期">{detailData.formalInFrontDate ? detailData.formalInFrontDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="合同到期日期">{detailData.contractExpireDate ? detailData.contractExpireDate : '--'}</Descriptions.Item>
              {/* <Descriptions.Item label="入职工龄">{detailData.entryWorkAge ? detailData.entryWorkAge : '--'}</Descriptions.Item>
              <Descriptions.Item label="社会工龄">{detailData.sociologyWorkAge ? detailData.sociologyWorkAge : '--'}</Descriptions.Item> */}
              <Descriptions.Item label="合同期限">{detailData.contractPeriod}</Descriptions.Item>
              <Descriptions.Item label="换签日期">{detailData.signChangeDate ? detailData.signChangeDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="实习协议到期日">{detailData.intshDueDate ? detailData.intshDueDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="入职节点年龄">{detailData.inductionAge}</Descriptions.Item>
              <Descriptions.Item label="当前节点年龄">{detailData.age}</Descriptions.Item>
              <Descriptions.Item label="时间区分">{detailData.timeDivision ? detailData.timeDivision : '--'}</Descriptions.Item>
            </Descriptions>
            <div className="lines"></div>
            <Descriptions layout="vertical">
              <Descriptions.Item label="离职方式">{detailData.isOnJob === 1 ? '--' : detailData.leaveJobTypeCn}</Descriptions.Item>
              <Descriptions.Item label="离职报备日期">{detailData.leaveApplyDate ? detailData.leaveApplyDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="离职最后办理时间">{detailData.shouldLeaveDate ? detailData.shouldLeaveDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="实际办理离职时间">{detailData.leaveDate ? detailData.leaveDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="工资结算日期">{detailData.wageDate ? detailData.wageDate : '--'}</Descriptions.Item>
              <Descriptions.Item label="工资结算时间">{detailData.wageTimeCn ? detailData.wageTimeCn : '--'}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="detail"></div>
            <div className="details"></div>
            <Descriptions title="入职信息" layout="vertical">
              <Descriptions.Item label="入职时社会工龄">{detailData.entryWorkAge}</Descriptions.Item>
              <Descriptions.Item label="公司工龄">{detailData.workAge}</Descriptions.Item>
              <Descriptions.Item label="社会工龄">{detailData.sociologyWorkAge}</Descriptions.Item>
              <Descriptions.Item label="公司用名">{detailData.staffName}</Descriptions.Item>
              <Descriptions.Item label="公司邮箱">{detailData.workEmail}</Descriptions.Item>
              <Descriptions.Item label="企业QQ">{detailData.enterpriseQQ ? detailData.enterpriseQQ : '--'}</Descriptions.Item>
              <Descriptions.Item label="社会工龄证明">
                {detailData.seniorityCert ? <a href={detailData.seniorityCert} target="_blank" rel="noopener noreferrer">查看</a> : '--'}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="employee-details">
            <div className="details"></div>
            <Descriptions title="其他信息" layout="vertical">
              <Descriptions.Item label="考勤号">{detailData.attendanceNum ? detailData.attendanceNum : '--'}</Descriptions.Item>
              <Descriptions.Item label="考勤时区">{detailData.attendanceTimezoneShowCn}</Descriptions.Item>
              <Descriptions.Item label="核心员工">{detailData.isCorelCn}</Descriptions.Item>
            </Descriptions>
          </div>
        </TabPane>
        <TabPane tab="员工履历" key="2">
          <Table
            dataSource={detailList}
            rowKey="id"
            className="corp-table"
            pagination={false}
          >
            <Column title="日期" dataIndex="addTime" render={(text, record) => (
              <div>{text.slice(0, 10)}</div>
            )} />
            <Column title="变动原因" dataIndex="reasonCn" />
            <Column title="公司用名" dataIndex="staffName" />
            <Column title="所属公司" dataIndex="companyName" />
            <Column title="部门" dataIndex="departmentName" />
            <Column title="职级" dataIndex="jobPositionCn" />
            <Column title="岗位级别" dataIndex="jobStationCn" />
            <Column title="直接主管" dataIndex="leaderName" />
            <Column title="说明" dataIndex="remark" />
          </Table>
        </TabPane>
      </Tabs>
    </Spin>
  )
}

export default Details
