/**
 * 模块名称: 离职模块 -> 离职申请
 * @author zhuyan@372163.com
 */

import React, { useRef, useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  DatePicker,
  Modal,
  Icon,
  Button,
  Upload
} from 'antd'
import moment from 'moment';
import { upLoadModule } from '@/utils/common';
const { Dragger } = Upload;
const FormItem = Form.Item
const Option = Select.Option
const Addrecruit = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { seteditVisible, getList, setformLoading, addType, editId } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [companyData, setCompanyData] = useState('') // 基本信息转出人
  const [dataVal, setDataVal] = useState('') // 转入人信息
  const [transferInId, setTransferInId] = useState('')
  const [staffsFlag, setStaffsFlag] = useState(true)
  const [selectData, setSelectData] = useState({
    staffIdAll: [], //姓名
    leaveJobTypeAll: [], //离职方式
    setStaffAll: [], //交接人
  })
  const [jobVisible, setJobVisible] = useState(false)
  const [canLeaveJob, setCanLeaveJob] = useState(1)
  const [client, setClient] = useState([])  // 客户
  const [agentclient, setAgentclient] = useState([])  // 代理商客户
  const [invoice, setInvoice] = useState([]) // 发票
  const [order, setOrder] = useState([]) // 欠款
  const [vendor, setVendor] = useState([]) // 厂商id
  const draggerRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    onEdit()
  }, [])

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      // api.getStaffDirectStaffList({ limit: global.paramsLimit }),
      api.getStaffLeavejobDirectStaffList({ limit: global.paramsLimit }),
      api.getCommon({ type: 'staff_leave_job_type' }),
      api.getTransferInUserData({ name: '' }) // 交接人
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        staffIdAll: list[0].list,
        leaveJobTypeAll: list[1],
        setStaffAll: list[2]
      })
      return list
    }).catch(() => setformLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  // 编辑框
  const onEdit = () => {
    if (addType == 0) {
      getSelectData()
    }
    if (addType == 1) {
      setformLoading(true)
      api.getStaffLeavejobInfo({ id: editId }).then(res => {
        getSelectData().then(() => {
          setTransferInId(res.handoverStaffId)
          setDataVal({
            staffNo: res.handoverStaffInfo.staffNo,
            companyName: res.handoverStaffInfo.companyName,
            departmentName: res.handoverStaffInfo.departmentName,
            jobPositionCn: res.handoverStaffInfo.jobPositionCn,
            staffName: res.handoverStaffInfo.staffName,
            entryDate: res.handoverStaffInfo.entryDate
          })
          setCompanyData({
            staffNo: res.staffInfo.staffNo,
            companyName: res.staffInfo.companyName,
            departmentName: res.staffInfo.departmentName,
            jobPositionCn: res.staffInfo.jobPositionCn,
            staffName: res.staffInfo.staffName,
            entryDate: res.staffInfo.entryDate,
            isNewProcedure: res.isNewProcedure
          })
          setFieldsValue({
            staffId: res.staffId,
            leaveReportDate: res.leaveReportDate ? moment(res.leaveReportDate) : null,
            leaveJobType: res.leaveJobType,
            // handoverStaffId: Number(res.handoverStaffId),
            handoverStaffId: res.handoverStaffInfo.staffNo,
            leaveJobReason: res.leaveJobReason,
            remark: res.remark
          })
          if(res.uploadFile && res.uploadFile.length > 0) {
            res.uploadFile.map((item,index)=>{
              fileList.push({
                status: 'done',
                uid: index,
                name: item.name,
                url: item.url
              })
            })
            setFileList([...fileList])
          }
          if (res.isNewProcedure) {
            setFieldsValue({ mobile: res.staffInfo.workMobile })
          } else {
            setFieldsValue({ 
              wagesSettleDate: res.wagesSettleDate ? moment(res.wagesSettleDate) : null ,
              wagesSettleTime: res.wagesSettleTime ?  res.wagesSettleTime : null
            }) 
          }
        })
      })
    }
  }

  // 保存离职
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        if (transferInId === '') {
          return message.error("交接员工编号不存在")
        }
        let uploadFile = fileList.map((item) => ({
          name: item.name,
          url: item.url,
        }));
        setSaveLoading(true)
        api.saveStaffLeavejobInfo({
          id: vals.id,
          staffId: vals.staffId,
          leaveReportDate: moment(vals.leaveReportDate).format('YYYY-MM-DD'),
          wagesSettleDate: vals.wagesSettleDate ? moment(vals.wagesSettleDate).format('YYYY-MM-DD') : '',
          wagesSettleTime: vals.wagesSettleTime ? vals.wagesSettleTime : '',
          leaveJobType: vals.leaveJobType,
          // handoverStaffId: vals.handoverStaffId,
          handoverStaffId: transferInId,
          leaveJobReason: vals.leaveJobReason,
          remark: vals.remark,
          mobile: vals.mobile ? vals.mobile : '',
          uploadFile
        }).then(() => {
          setSaveLoading(false)
          seteditVisible(false)
          message.success('添加成功')
          getList()
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const onSelectComp = (e) => {
    // setFieldsValue({
    //   handoverStaffId: undefined
    // })
    selectData.staffIdAll.forEach((item, index, arr) => {
      //console.log(item, index, arr)
      if (item.id === e) {
        setCompanyData(item)
      }
      setStaffsFlag(false)
    })
    setCanLeaveJob(0)
    api.getStaffCanLeaveJob({ id: e }).then((res) => {
      if (+res.canLeaveJob === 0) {
        setJobVisible(true)
        setCanLeaveJob(+res.canLeaveJob)
        setClient(res.clientList)
        setAgentclient(res.agentclientList)
        setInvoice(res.invoiceList)
        setOrder(res.orderList)
        setVendor(res.vendorList)
      } else {
        setJobVisible(false)
        setCanLeaveJob(+res.canLeaveJob)
        setClient([])
        setAgentclient([])
        setInvoice([])
        setOrder([])
        setVendor([])
      }
      validateFields(['handoverStaffId'],(error)=>{
        console.log(error);
      })
    }).catch()
  }

  const opinionTitle = () => {
    return <>
      <Icon type="close-circle" theme="filled" style={{ color: 'red', marginRight: 8 }} />
      <span>该员工不满足离职条件</span>
    </>
  }

  const onChangeNo = (e) => {
    setDataVal('')
    setTransferInId('')
    if (e.target.value) {
      api.getTransferInUserList({ no: e.target.value }).then(res => {
        if (res.length !== 0) {
          setDataVal(res[0])
          setTransferInId(res[0].id)
        } else {
          setDataVal('')
          setTransferInId('')
        }
      })
    }
  }

  // 文件上传
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.png, .jpg, .zip, .PDF',
    allowSizeType: 2,
  });

  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="姓名">
          {getFieldDecorator('staffId', {
            rules: [{ required: true, message: '请选择姓名' }]
          })(
            <Select
              placeholder="请选择姓名"
              onSelect={onSelectComp}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {selectData.staffIdAll.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
            </Select>
          )}
        </FormItem>
        {
          companyData.isNewProcedure && <FormItem label="手机号">
            {getFieldDecorator('mobile', {
              rules: [{ required: true, message: '请输入手机号' }]
            })(
              <Input placeholder="请输入手机号" />
            )}
          </FormItem>
        }
        {!companyData.isNewProcedure && <FormItem label="离职报备时间">
          {getFieldDecorator('leaveReportDate', {
            rules: [{ required: true, message: '请选择离职报备时间' }]
          })(
            <DatePicker placeholder="请选择离职报备时间" />
          )}
        </FormItem>}
        {
          !companyData.isNewProcedure && <FormItem label="工资结算日期">
            {getFieldDecorator('wagesSettleDate', {
              rules: [{ required: true, message: '请选择工资结算日期' }]
            })(
              <DatePicker placeholder="请选择工资结算日期" />
            )}
          </FormItem>
        }
        {
          !companyData.isNewProcedure && <FormItem label="工资结算时间">
            {getFieldDecorator('wagesSettleTime', {
              initialValue: 2,
              rules: [{ required: true, message: '请选择工资结算时间' }]
            })(
              <Select
                placeholder="请选择工资结算时间"
                getPopupContainer={trigger => trigger.parentNode}
                style={{ width: '45%' }}
              >
                <Option value={1}>上午</Option>
                <Option value={2}>下午</Option>
              </Select>
            )}
          </FormItem>
        }
        <FormItem label="离职方式">
          {getFieldDecorator('leaveJobType', {
            rules: [{ required: true, message: '请选择离职方式' }]
          })(
            <Select
              placeholder="请选择离职方式"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {selectData.leaveJobTypeAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        {/* <FormItem label="交接员工">
          {getFieldDecorator('handoverStaffId', {
            rules: [{ required: true, message: '请选择交接员工' }]
          })(
            <Select
              placeholder="请选择交接员工"
              // disabled={staffsFlag}
              showSearch={true}
              optionFilterProp="children"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {selectData.setStaffAll.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
            </Select>
          )}
        </FormItem> */}
        <FormItem label="交接人员工编号">
          {getFieldDecorator('handoverStaffId', {
            rules: [
              { required: true, message: '请输入交接人员工编号' },
              {
                validator: (rule, value, callback) => {
                  if(!!value && companyData !== '' && companyData.staffNo === value) {
                    callback('交接人与离职人员不可为同一人')
                  }else{
                    callback()
                  }
                },
                message: '交接人与离职人员不可为同一人'
              },
            ],
          })(<Input placeholder='请输入交接人员工编号' onChange={onChangeNo} />)}
        </FormItem>
        <FormItem label="离职原因">
          {getFieldDecorator('leaveJobReason', {
            rules: [{ required: true, message: '请输入离职原因' }]
          })(
            <Input.TextArea placeholder="请输入离职原因" />
          )}
        </FormItem>
        <FormItem label="附件">
          <div
            className='dragger-box'
            ref={draggerRef}
            style={{ position: 'relative' }}
          >
            <Dragger {...uploadFiles}>
              <p className='ant-upload-drag-icon'>
                <Icon type='inbox' />
              </p>
              <p className='ant-upload-text'>点击或将文件拖拽到这里上传</p>
              <p className='ant-upload-hint'>
                支持扩展名：.png, jpg, .zip, .pdf 单个文件大小不超过5M。
              </p>
            </Dragger>
          </div>
        </FormItem>
        <FormItem label="备注">
          {getFieldDecorator('remark')(
            <Input.TextArea placeholder="请输入备注" />
          )}
        </FormItem>
        {
          dataVal !== '' && <FormItem label="转入人信息" style={{ marginBottom: '10px' }}>
            <Row gutter={24}>
              <Col span={12}>
                员工编号：{dataVal.staffNo}
              </Col>
              <Col span={12}>
                所属公司：{dataVal.companyName}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                所属部门：{dataVal.departmentName}
              </Col>
              <Col span={12}>
                职级：{dataVal.jobPositionCn}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                员工姓名：{dataVal.staffName}
              </Col>
              <Col span={12}>
                入职日期：{dataVal.entryDate}
              </Col>
            </Row>
          </FormItem>
        }
        {
          companyData !== '' && <FormItem label="转出人信息" style={{ marginBottom: '10px' }}>
            <Row gutter={24}>
              <Col span={12}>
                员工编号：{companyData.staffNo}
              </Col>
              <Col span={12}>
                所属公司：{companyData.companyName}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                所属部门：{companyData.departmentName}
              </Col>
              <Col span={12}>
                职级：{companyData.jobPositionCn}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                员工姓名：{companyData.staffName}
              </Col>
              <Col span={12}>
                入职日期：{companyData.entryDate}
              </Col>
            </Row>
          </FormItem>
        }
        {/* <BtnGroup style={{ marginTop: 20 }} onCancel={() => seteditVisible(false)} loading={saveLoading} /> */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
          <Button onClick={() => seteditVisible(false)}>取消</Button>
          <Button loading={saveLoading} type="primary" htmlType="submit" style={{ marginLeft: 30 }} disabled={+canLeaveJob === 1 ? false : true}>确定</Button>
        </div>
      </Form>
      <Modal
        // title="该员工不满足离职条件"
        title={opinionTitle()}
        visible={jobVisible}
        width={600}
        maskClosable={false}
        onCancel={() => { setJobVisible(false) }}
        footer={[
          <Button key="Btn1" type="primary" onClick={() => { setJobVisible(false) }}>
            知道了
          </Button>
        ]}
      >
        {invoice.length !== 0 && <div style={{ marginBottom: 10 }}>
          该员工有未收款发票：<br />
          <Row gutter={24}>
            {invoice && invoice.map(item => <Col key={item.id} span={6}>{item.name}</Col>)}
          </Row>
        </div>}
        {order.length !== 0 && <div style={{ marginBottom: 10 }}>
          该员工有客户欠款：<br />
          <Row gutter={24}>
            {order && order.map(item => <Col key={item.id} span={8}>{item.name}</Col>)}
          </Row>
        </div>
        }
        {client.length !== 0 && <div style={{ marginBottom: 10 }}>
          该员工有未转出直销客户：<br />
          <Row gutter={24}>
            {client && client.map(item => <Col key={item.id} span={4}>{item.id}</Col>)}
          </Row>
        </div>}
        {agentclient.length !== 0 && <div style={{ marginBottom: 10 }}>
          该员工有未转出代理商：<br />
          <Row gutter={24}>
            {agentclient && agentclient.map(item => <Col key={item.id} span={4}>{item.id}</Col>)}
          </Row>
        </div>}
        {vendor.length !== 0 && <div style={{ marginBottom: 10 }}>
          该员工有未转出厂商ID：<br />
          <Row gutter={24}>
            {vendor && vendor.map(item => <Col key={item.id} span={8}>{item.name}</Col>)}
          </Row>
        </div>}
      </Modal>
    </div>
  );
}

export default Form.create()(Addrecruit)

