/**
 * 模块名称: 离职管理
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Modal,
  Spin,
  Switch,
  Upload
} from 'antd'
import { upLoadModule } from '@/utils/common'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Export from '@/components/Export'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Adddimission from './Adddimission'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
  staffNo: '',
  staffName: '',
  companyId: '',
  departmentId: '',
  approvalStatus: '',
  jobPosition: '',
  addTimeMin: '',
  addTimeMax: '',
  type: 2
}
let params = {}

const Recruit = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const productsRef = useRef()
  const draggerlwRef = useRef(null)
  const draggerwtRef = useRef(null)
  const draggerczRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [batchDepartment, setBatchDepartment] = useState([])  //部门
  const [approvalStatusAll, setApprovalStatusAll] = useState([]) // 审批状态
  const [jobPosition, setjobPosition] = useState([]) //职级
  const [visible, setVisible] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [filterContent, setFilterContent] = useState([])
  const [statusVal, setStatusVal] = useState(undefined)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [editVisible, seteditVisible] = useState(false)
  const [addType, setAddType] = useState(0)
  const [editId, setEditId] = useState(null)
  const [authList, setAuthList] = useState([])
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  const [showExport, setShowExport] = useState(false)//显示导出浮层
  const [compass, setCompass] = useState({})
  const [compassVisible, setCompassVisible] = useState(false)
  const [fileListlw, setFileListlw] = useState([])//劳务合同上传附件
  const [fileListwt, setFileListwt] = useState([])//委托书上传附件
  const [fileListcz, setFileListcz] = useState([])//辞职信上传附件
  const search = parseSearch(props.location.search)

  useEffect(() => {
    shouldMe = null
    apiOperateTimer = true

    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      filter = {
        staffNo: '',
        staffName: '',
        companyId: '',
        departmentId: '',
        approvalStatus: '',
        jobPosition: '',
        addTimeMin: '',
        addTimeMax: '',
        tureLeaveDateMin: '',
        tureLeaveDateMax: '',
        type: 2
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      filter.companyId = ''
      // console.log(filter)
      setTimeout(() => {
        setFieldsValue({
          staffNo: filter.staffNo !== '' ? filter.staffNo : undefined,
          staffName: filter.staffName !== '' ? filter.staffName : undefined,
          departmentId: filter.departmentId !== '' ? filter.departmentId : undefined,
          approvalStatus: filter.approvalStatus !== '' ? filter.approvalStatus : undefined,
          jobPosition: filter.jobPosition !== '' ? filter.jobPosition : undefined,
          addTime: filter.addTimeMin !== '' ? [moment(filter.addTimeMin), moment(filter.addTimeMax)] : undefined,
          tureLeaveDate: filter.tureLeaveDateMin !== '' ? [moment(filter.tureLeaveDateMin), moment(filter.tureLeaveDateMax)] : undefined,
          type: 2
        })
      }, 1000)
    }
    getList()
    onDepartment()
    onsetApprovalStatus()
    onJobPosition()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    if (apiOperateTimer) {
      setLoading(true)
      if (shouldMe) {
        filter.shouldMe = shouldMe
      } else {
        for (let i in filter) {
          if (i == 'shouldMe') delete filter[i]
        }
      }
      let params = { ...filter, limit: pageSize, page: currentPage }
      for (let i in params) {
        if (i === 'addTime' || i === 'tureLeaveDate' || params[i] === '' || params[i] === undefined) delete params[i]
      }
      api.getStaffLeavejobList(params).then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    } else {
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if (e == true) {
      shouldMe = 1
    } else {
      shouldMe = null
    }
    getList()
  }


  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          addTimeMin: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          addTimeMax: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : '',
          tureLeaveDateMin: vals.tureLeaveDate ? vals.tureLeaveDate[0].format('YYYY-MM-DD') : '',
          tureLeaveDateMax: vals.tureLeaveDate ? vals.tureLeaveDate[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      staffNo: '',
      staffName: '',
      companyId: filter.companyId,
      departmentId: '',
      approvalStatus: '',
      jobPosition: '',
      addTimeMin: '',
      addTimeMax: '',
      type: 2
    }
    setStatusVal(undefined)
    setStatusValName(undefined)
    shouldMe = null
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 部门
  const onDepartment = () => {
    api.getSelectDepartmentList({
      companyId: filter.companyId,
      limit: global.paramsLimit
    }).then(data => {
      setBatchDepartment(data.list)
    }).catch(() => setLoading(false))
  }

  // 审批状态
  const onsetApprovalStatus = () => {
    api.getAuditStatusList({ type: '1' }).then(data => {
      setApprovalStatusAll(data)
    })
  }

  // 职级
  const onJobPosition = () => {
    api.getCommon({ type: 'staff_job_position' }).then(data => {
      setjobPosition(data)
    })
  }

  // 补充资料(编辑)
  const onEdit = (data) => {
    seteditVisible(true)
    setAddType(1)
    setEditId(data.id)
  }

  //资料修改
  const onCompass = (data) => {
    setCompassVisible(true)
    setformLoading(true)
    api.getStaffLeavejobData({ id: data.id }).then(data => {
      setCompass(data)
      //解除劳务协议
      if(data.terminationAgreement && Array.isArray(JSON.parse(data.terminationAgreement)) && JSON.parse(data.terminationAgreement).length > 0) {
        JSON.parse(data.terminationAgreement).map((item,index)=>{
          fileListlw.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileListlw(fileListlw)
      }
      //辞职信
      if(data.letterResignation && Array.isArray(JSON.parse(data.letterResignation)) && JSON.parse(data.letterResignation).length > 0) {
        JSON.parse(data.letterResignation).map((item,index)=>{
          fileListcz.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileListcz(fileListcz)
      }
      //委托书
      if(data.powerAttorney && Array.isArray(JSON.parse(data.powerAttorney)) && JSON.parse(data.powerAttorney).length > 0) {
        JSON.parse(data.powerAttorney).map((item,index)=>{
          fileListwt.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileListwt(fileListwt)
      }
      setformLoading(false)
    }).catch(() => setformLoading(false))
  }

  //离职申请
  const onAddRole = () => {
    setAddType(0)
    seteditVisible(true)
  }

  // 删除
  const onDelete = (id) => {
    api.delLeavejobUpdateIsDel({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    setBatchDepartment([])
    setFieldsValue({ 'departmentId': undefined })
    filter.departmentId = ''
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime' || k === 'tureLeaveDate') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[value].content
    setFieldsValue({
      staffNo: data.staffNo ? data.staffNo : undefined,
      staffName: data.staffName ? data.staffName : undefined,
      departmentId: data.departmentId ? data.departmentId : undefined,
      approvalStatus: data.approvalStatus ? data.approvalStatus : undefined,
      jobPosition: data.jobPosition ? data.jobPosition : undefined,
      addTime: data.addTime ? [moment(data.addTime[0], 'YYYY-MM-DD'), moment(data.addTime[1], 'YYYY-MM-DD')] : undefined,
      tureLeaveDate: data.tureLeaveDate ? [moment(data.tureLeaveDate[0], 'YYYY-MM-DD'), moment(data.tureLeaveDate[1], 'YYYY-MM-DD')] : undefined
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const closeDrawer = () => {
    seteditVisible(false)
  }

  const onDropdownVisible = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: filter.companyId }).then(data => setBatchDepartment(data.list)) //部门
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
  }

   // 附件上传 劳务合同解除
   const uploadFileslw = upLoadModule({
    fileList: fileListlw,
    setFileList: setFileListlw,
    draggerRef: draggerlwRef,
    accept: '.rar, .zip, .jpg, .png, .pdf',
    limitNum: 20
  })

  // 附件上传 委托书
  const uploadFileswt = upLoadModule({
    fileList: fileListwt,
    setFileList: setFileListwt,
    draggerRef: draggerwtRef,
    accept: '.rar, .zip, .jpg, .png, .pdf',
    limitNum: 20
  })

  // 附件上传 辞职信
  const uploadFilescz = upLoadModule({
    fileList: fileListcz,
    setFileList: setFileListcz,
    draggerRef: draggerczRef,
    accept: '.rar, .zip, .jpg, .png, .pdf',
    limitNum: 20
  })

  //关闭按钮
  const onCloseCompass = () => {
    setCompassVisible(false)
    setFileListlw([])
    setFileListcz([])
    setFileListwt([])
  }

  //修改资料提交
  const onClickSubmit = () => {
    // console.log('compass.id=>', compass.id)
    // console.log('compass.leaveJobType=>', compass.leaveJobType)
    // console.log('compass.isSelfHandle=>', compass.isSelfHandle)
    // console.log('劳务合同解除=>', fileListlw)
    // console.log('委托书=>', fileListwt)
    // console.log('辞职信=>', fileListcz)
    //劳务合同解除提示填写
    if (!fileListlw.length > 0) {
      return message.error('请补充完整后提交')
    }
    //如果有辞职信 没有填写 提示填写
    if (compass.leaveJobType === 0 && !fileListcz.length > 0) {
      return message.error('请补充完整后提交')
    }
    //如果有委托书，没有填写 提示填写
    if (compass.isSelfHandle === 0 && !fileListwt.length > 0) {
      return message.error('请补充完整后提交')
    }
    let params = {
      id: compass.id,
      isSelfHandle: compass.isSelfHandle,
      terminationAgreement: JSON.stringify(fileListlw)
    }
    if (compass.leaveJobType === 0) {
      params.letterResignation = JSON.stringify(fileListcz)
    }
    if (compass.isSelfHandle === 0) {
      params.powerAttorney = JSON.stringify(fileListwt)
    }
    // console.log(params)
    setformLoading(true)
    api.setModifyLeavejobData(params).then(res => {
      message.success('修改成功')
      setCompassVisible(false)
      setformLoading(false)
      setFileListlw([])
      setFileListcz([])
      setFileListwt([])
    }).catch(() => setformLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="姓名" labelCol={{ span: 8 }}>
                  {getFieldDecorator('staffName')(<Input placeholder="请输入员工姓名" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="员工号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('staffNo')(<Input placeholder="请输入员工号" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="部门" labelCol={{ span: 8 }}>
                  {getFieldDecorator('departmentId')(
                    <Select placeholder="全部"
                      dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={bool => bool && onDropdownVisible()}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {batchDepartment.map(v => {
                        return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="职级" labelCol={{ span: 8 }}>
                  {getFieldDecorator('jobPosition')(
                    <Select placeholder="全部"
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {jobPosition.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="审批状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('approvalStatus')(
                    <Select placeholder="全部"
                      dropdownMatchSelectWidth={false}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {approvalStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="申请时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="实际离职时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('tureLeaveDate')(<RangePicker allowClear={false} span={12} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
              >
                {filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        {/* <Auth auths={authList} code="export"> */}
          <Button type="primary" icon="upload" onClick={onExportFile}>导出</Button>
        {/* </Auth> */}
        <Export
          show={showExport}
          onCancel={() => {
            setShowExport(false)
            productsRef.current.changeVal()
          }}
          tplUrl="getStaffLeavejobExportTpl"
          fieldsUrl="getStaffLeavejobTplItems"
          saveUrl="setStaffLeavejobSaveExportTpls"
          exportUrl="exportStaffLeavejob"
          method="export"
          parame={{ ExportType: 'staffLeavejob.export', ...filter }}
          cRef={productsRef}
        />
        <Auth auths={authList} code="staff-attendance-apply">
          <Button type="primary" icon="user-delete" onClick={onAddRole} style={{ marginLeft: 15 }}>离职申请</Button>
        </Auth>
      </div>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2200 }}
        onChange={onChangeTable}
      >
        <Column title="姓名"
          dataIndex="staffName"
          fixed="left"
          width={120}
          render={(companyName, record) => <Link to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>{companyName}</Link>}
        />
        <Column title="所属公司" dataIndex="companyName" fixed="left" width={100} />
        <Column title="部门" dataIndex="departmentName" fixed="left" width={150} />
        <Column title="员工编号" dataIndex="staffInfo.staffNo" />
        <Column title="职级" dataIndex="jobPositionCn" />
        <Column title="人员类型" dataIndex="staffInfo.isFormalCn" />
        <Column title="手机号" dataIndex="staffInfo.workMobile" />
        <Column title="入职日期" dataIndex="staffInfo.entryDate" />
        <Column title="工资结算日期" dataIndex="wagesSettleDate" />
        <Column title="工资结算时间" dataIndex="wagesSettleTimeCn" />
        <Column title="离职方式" dataIndex="leaveJobTypeCn" />
        <Column title="直接主管" dataIndex="staffInfo.leaderName" />
        <Column title="离职报备日期" dataIndex="leaveReportDate" />
        <Column title="应办离职最后日期" dataIndex="shouldLeaveDate" />
        <Column title="应办离职月份" dataIndex="shouldLeaveMonth" />
        <Column title="实际办理离职日期" dataIndex="tureLeaveDate" />
        <Column title="实际办离职月份" dataIndex="tureLeaveMonth" />
        <Column title="审批状态" dataIndex="approvalStatusCn" />
        <Column title="离职申请时间" dataIndex="addTime" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Tooltip title="在新页面打开详情页" placement="bottom">
              <Link target='_blank' to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>
                <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginRight: '8px' }} />
              </Link>
            </Tooltip>
            <Auth auths={text.operateAuth} code="leave-job-data-modify"> 
              {record.approvalStatus === 4 && <Tooltip title="资料修改" placement="bottom">
                <Icon style={{ color: '#1890ff', fontSize: '16px' }} type="compass" onClick={() => onCompass(record)} />
              </Tooltip>}
            </Auth>
            {
              (record.userAuth && record.userAuth.allowReplenish) || (record.userAuth && record.userAuth.allowRakeUp) ?
                <Tooltip title="编辑" placement="bottom">
                  <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
                </Tooltip> : null
            }
            {' '}
            {
              record.userAuth && record.userAuth.allowDelete ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }

          </>
        )} />
      </Table>
      <Drawer
        title="离职申请"
        width={600}
        onClose={closeDrawer}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <Adddimission
            setformLoading={setformLoading}
            seteditVisible={seteditVisible}
            getList={getList}
            addType={addType}
            editId={editId}
          />
        </Spin>
      </Drawer>
      <Drawer
        title="资料修改"
        width={700}
        onClose={() => onCloseCompass()}
        visible={compassVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <div className="compass-title">
            <div className='info'>员工编号: {compass.applyStaffNo}</div>
            <div className='info'>员工姓名: {compass.staffName}</div>
            <div className='info'>离职方式: {compass.leaveJobTypeCn}</div>
          </div>
          <div className='compass-upload'>
            <div className='compass-label'><span style={{ color: 'red', marginRight: '2px' }}>*</span>劳动合同解除协议：</div>
            <div className="dragger-box" ref={draggerlwRef} >
              <Upload
                {...uploadFileslw}
              >
                <Button>
                  <Icon type="upload" /> 请选择文件
                </Button>
                <p className="ant-upload-hint">
                  支持扩展名：.png、.jpg、.pdf、.zip 单个文件大小不超过5M，不超过20个附件
                </p>
              </Upload>
            </div>
          </div>
          {
            compass.leaveJobType === 0 ?
              <div className='compass-upload'>
                <div className='compass-label'><span style={{ color: 'red', marginRight: '2px' }}>*</span>辞职信：</div>
                <div className="dragger-box" ref={draggerczRef}>
                  <Upload
                    {...uploadFilescz}
                  >
                    <Button>
                      <Icon type="upload" /> 请选择文件
                    </Button>
                    <p className="ant-upload-hint">
                      支持扩展名：.png、.jpg、.pdf、.zip 单个文件大小不超过5M，不超过20个附件
                    </p>
                  </Upload>
                </div>
              </div> : null
          }
          {
            compass.isSelfHandle == 0 ? <div className='compass-upload'>
              <div className='compass-label'><span style={{ color: 'red', marginRight: '2px' }}>*</span>委托书：</div>
              <div className="dragger-box" ref={draggerwtRef}>

                <Upload 
                  {...uploadFileswt}
                >
                  <Button>
                    <Icon type="upload" /> 请选择文件
                  </Button>
                  <p className="ant-upload-hint">
                    支持扩展名：.png、.jpg、.pdf、.zip 单个文件大小不超过5M，不超过20个附件
                  </p>
                </Upload>
              </div>
            </div> : null
          }
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
            <Button onClick={() => onCloseCompass()}>取消</Button>
            <Button loading={formLoading} type="primary" onClick={onClickSubmit} style={{ marginLeft: 30 }}>确定</Button>
          </div>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(Recruit)